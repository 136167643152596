import * as React from "react";
import { nsTranslate } from "../../i18n";
import { WithTranslation } from "tim-bridge";

interface EmptyImageMessageProps {
  className?: string;
}

interface EmptyImageMessageInnerProps extends EmptyImageMessageProps, WithTranslation {}

const EmptyImageMessage = (props: EmptyImageMessageInnerProps) => (
  <div key={"emptyImageMessage"} className={props.className}>
    {props.t("emptyImage.message", { defaultValue: "No image available" })}
  </div>
);

export default nsTranslate(EmptyImageMessage);
