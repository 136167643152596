import { SufficiencyResponse, Sufficiencies } from "../../ProductSummary/graphql/types";
import { Attribute } from "../../Product/types";
import { AttributeValueBase } from "@stibo/value-components";

export interface UpdateNodeInput {
  clientMutationId?: string;
  contextStepId: string;
  workspaceStepId: string;
  nodeType: StepMaintainableNodeTypes;
  stepId: string;
  name?: string;
  values?: AttributeValueInput[];
}

interface AttributeValueInput {
  attributeStepId: string;
  value: ValueInput | ValueInput[];
}

interface ValueInput {
  value: string;
  valueStepId?: string | null;
  unitStepId?: string | null;
}

export enum StepMaintainableNodeTypes {
  BackgroundProcess = "BackgroundProcess",
  NodeCollection = "NodeCollection",
  Asset = "Asset",
  Classification = "Classification",
  Entity = "Entity",
  Product = "Product",
}

export interface StepNode {
  id: string;
  internalId?: string;
  stepId?: string;
  objectType?: any; // ObjectType;
  name?: string;
  title?: string;
  url?: string;
  values?: ValueConnection;
  value?: Value;
}

export interface ValueConnection {
  edges: ValueEdge[];
  // pageInfo: PageInfo
}

export interface UpdateNodeOutput extends ApolloResponse {
  clientMutationId: string;
  updated: StepNode;
}

export interface ValueEdge {
  node: AttributeValueBase;
  cursor: string;
}

interface Value {
  internalId: string;
  simpleValue: string;
  rawSimpleValue: string;
  attribute: Attribute;
}

export interface ErrorList extends ApolloResponse {
  clientMutationId: string;
  errors: string[];
}

export interface ApolloResponse {
  loading: boolean;
  networkStatus: number;
}

export type UpdateNodePayload = UpdateNodeOutput | ErrorList;

export interface UpdateSufficiencies extends ApolloResponse {
  clientMutationId: string;
  sufficiencies: Sufficiencies;
}
