import { TimGraphApollo } from "tim-bridge";
import { GetNode } from "./types";
import { node } from "./queries/summary";

export interface IGraphQLClient {
  getNode: GetNode;
}

export interface ClientOptions {
  contextID: string;
  workspaceID: string;
}

export class GraphQLClient implements IGraphQLClient {
  client: TimGraphApollo;
  constructor(client: TimGraphApollo, private options: ClientOptions) {
    this.client = client;
  }

  getNode: GetNode = (stepId: string, nodeType: string) => {
    const { workspaceID, contextID } = this.options;
    return this.client.query({
      query: node,
      variables: {
        contextStepId: contextID,
        workspaceStepId: workspaceID,
        nodeType: nodeType,
        stepId,
      },
      fetchPolicy: "no-cache",
    });
  };
}
