import * as React from "react";

import { nsTranslate, WithTranslation } from "../../../../../i18n";
import { TagProps } from "../types";

interface NotInApprovedWorkspaceProps {}
interface NotInApprovedWorkspaceInnerProps extends NotInApprovedWorkspaceProps, WithTranslation {}

const NotInApprovedWorkspace = (props: NotInApprovedWorkspaceInnerProps & TagProps) => {
  const { className, t } = props;
  return (
    <span className={className} id={"ProductSummaryNotInApprovedWorkspace"}>
      {t("notInApprovedWorkspace.text", { defaultValue: "Never been approved" })}
    </span>
  );
};

export default nsTranslate(NotInApprovedWorkspace);
