import * as React from "react";
import { TableRow, TableCell, createStyles, withStiboStyles, WithStiboStyles } from "tim-ui";
import { generateTestID } from "../../../common/generateTestID";
import { ValueBaseProps } from "./ValueBase";
import { BulletValueList } from "./BulletValueList";

export const classNames = {
  tableRow: {},
  tableCellTopAlignedLabel: {},
  tableCellLeftAligned: {
    paddingLeft: "0!important",
    paddingRight: "0!important",
  },
};

const styles = () => createStyles(classNames);

export const ShortMultiValueBase: React.SFC<ValueBaseProps & WithStiboStyles<typeof styles>> = props => {
  const { id, value, label, classes } = props;

  return (
    <TableRow className={classes.tableRow} key={id}>
      <TableCell className={classes.tableCellLeftAligned} id={generateTestID(`TableCellLabel_${label}`)}>
        {label}
      </TableCell>
      <TableCell className={classes.tableCellLeftAligned} id={generateTestID(`TableCellValue_${label}`)}>
        <BulletValueList value={value} />
      </TableCell>
    </TableRow>
  );
};

const ShortMultiValue = withStiboStyles(styles)(ShortMultiValueBase);

export { ShortMultiValue };
