const screenDefinitions = {
  iPad: "@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)",
  iPhoneX:
    "@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3)",
  iPhones: "@media only screen and (min-device-width : 375px) and (max-device-width : 667px)", // iPhone 6, 7, 8
  iPhonesPlus: "@media only screen and (min-device-width : 414px) and (max-device-width : 736px)", // iPhone 6, 7, 8 plus
  smallIPhones: "@media only screen and (min-device-width : 320px) and (max-device-width : 568px)",
  smallMobile: "@media all and (min-width: 0) and (max-width: 360px)",
  largeMobile: "@media all and (min-width: 361px) and (max-width: 840px)",
};

const screenOrientations = {
  portrait: "and (orientation: portrait)",
  landscape: "and (orientation: landscape)",
};

const breakPoints = [720, 960, 1920];

const gridBrakeBoints = {
  columns4: `@media screen and (min-width: ${breakPoints[2]}px)`,
  columns3: `@media screen and (min-width: ${breakPoints[1]}px) and (max-width: ${breakPoints[2]}px)`,
  columns2: `@media screen and (min-width: ${breakPoints[0]}px) and (max-width: ${breakPoints[1]}px)`,
  columns1: `@media screen and (max-width: ${breakPoints[0]}px)`,
};

const mediaQueries = {
  iPadLandscape: `${screenDefinitions.iPad} ${screenOrientations.landscape}`,
  iPadPortrait: `${screenDefinitions.iPad} ${screenOrientations.portrait}`,
  iPhoneXLandscape: `${screenDefinitions.iPhoneX} ${screenOrientations.landscape}`,
  iPhoneXPortrait: `${screenDefinitions.iPhoneX} ${screenOrientations.portrait}`,
  iPhonesLandscape: `${screenDefinitions.iPhones} ${screenOrientations.landscape}`,
  iPhonesPortrait: `${screenDefinitions.iPhones} ${screenOrientations.portrait}`,
  smallIPhonesLandscape: `${screenDefinitions.smallIPhones} ${screenOrientations.landscape}`,
  smallIPhonesPortrait: `${screenDefinitions.smallIPhones} ${screenOrientations.portrait}`,
  smallMobileLandscape: `${screenDefinitions.smallMobile} ${screenOrientations.landscape}`,
  smallMobilePortrait: `${screenDefinitions.smallMobile} ${screenOrientations.portrait}`,
  largeMobileLandscape: `${screenDefinitions.largeMobile} ${screenOrientations.landscape}`,
  largeMobilePortrait: `${screenDefinitions.largeMobile} ${screenOrientations.portrait}`,
  ...gridBrakeBoints,
};

export { breakPoints, mediaQueries };
