import React = require("react");

import {
  MemberType,
  TProductImageMemberRenderer,
  TCarouselMemberRenderer,
  TAttributeGroupMemberRenderer,
} from "./types";
import AttributeGroupCard from "../../../card/AttributeGroupCard";
import Carousel from "../../../carousel/Carousel";
import ProductImage from "../../../productImage/ProductImage";
// import { ReferencedData } from "../ProductViewer/BusinessObjects/ReferencedData";
// import { TBusinessObjectMemberRenderer } from "../ProductViewer/BusinessObjects/types";

const attributeGroupMemberRenderer: TAttributeGroupMemberRenderer = ({ member, index }) => {
  const title: string = member.value.title ? member.value.title : "";

  return member.value ? (
    <AttributeGroupCard title={title} key={index} testKey={index} attributeGroups={member.value} />
  ) : (
    <></>
  );
};

const carouselMemberRenderer: TCarouselMemberRenderer = ({ member, index }) => {
  return <Carousel pictures={member.value} key={index} />;
};

const productImageMemberRenderer: TProductImageMemberRenderer = ({ member, index }) => {
  return <ProductImage image={member.value} key={index} />;
};

// const businessObjectMemberRenderer: TBusinessObjectMemberRenderer = ({ member, index }) => {
//   return <ReferencedData key={index} businessObject={member.value} />;
// };

interface MemberRenderer {
  [MemberType.attributeGroup]: TAttributeGroupMemberRenderer;
  [MemberType.carousel]: TCarouselMemberRenderer;
  [MemberType.productImage]: TProductImageMemberRenderer;
  // [MemberType.businessObject]: TBusinessObjectMemberRenderer;
}

const memberRenderer: MemberRenderer = {
  [MemberType.attributeGroup]: attributeGroupMemberRenderer,
  [MemberType.carousel]: carouselMemberRenderer,
  [MemberType.productImage]: productImageMemberRenderer,
  // [MemberType.businessObject]: businessObjectMemberRenderer
};

export { memberRenderer, attributeGroupMemberRenderer, carouselMemberRenderer, productImageMemberRenderer };
