import * as React from "react";
import { Tooltip } from "tim-ui";
import { Moment } from "moment";
import { WithTranslation } from "tim-bridge";

import { nsTranslate } from "../../../../../i18n";

interface LastUpdateDateProps {
  lastUpdateDate: Moment;
}

interface LastUpdateDateInnerProps extends LastUpdateDateProps, WithTranslation {}

class LastUpdateDate extends React.Component<LastUpdateDateInnerProps> {
  render() {
    const { lastUpdateDate, t } = this.props;
    return (
      <Tooltip
        title={`${t("lastUpdateDate.tooltip", { defaultValue: "Last updated" })} ${lastUpdateDate.format("LLLL")}`}
        placement="top"
      >
        <span id={"ProductSummaryLastUpdateDate"}>
          {t("lastUpdateDate.text", { defaultValue: "Updated" })} {lastUpdateDate.fromNow()}
        </span>
      </Tooltip>
    );
  }
}

export default nsTranslate(LastUpdateDate);
