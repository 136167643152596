import * as React from "react";
import { ImgHTMLAttributes, useState } from "react";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";

interface Props extends ImgHTMLAttributes<any> {
  fallbackMessage?: string;
}

export type ThumbnailClasses = "thumbnailContainer" | "thumbnail" | "fallback";

const styles = (stiboTheme): StyleRules<ThumbnailClasses> =>
  createStyles({
    thumbnailContainer: {
      clear: "both",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "12px",
      backgroundColor: "lightgray",
      color: stiboTheme.palette.black[200],
      border: "1px solid #BDBDBD",
      borderRadius: "2px",
      height: "64px",
      width: "64px",
      marginRight: "16px",
      overflow: "hidden",
    },
    thumbnail: {
      objectFit: "cover",
      aspectRatio: "1/1",
      marginRight: "8px",
      height: "100%",
    },
    fallback: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  });

function ThumbnailWithFallback({
  fallbackMessage,
  src,
  classes,
  onClick,
  ...props
}: Props & WithStiboStyles<ThumbnailClasses>) {
  const [srcError, setSrcError] = useState<boolean>(false);
  const onError = () => setSrcError(true);

  return !Boolean(src) || (srcError && !Boolean(fallbackMessage)) ? null : (
    <div className={classes.thumbnailContainer}>
      {srcError ? (
        <div className={classes.fallback} {...props}>
          <div>{fallbackMessage}</div>
        </div>
      ) : (
        <img className={classes.thumbnail} src={src} onError={onError} onClick={onClick} {...props} />
      )}
    </div>
  );
}

export default withStiboStyles(styles)(ThumbnailWithFallback);
