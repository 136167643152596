import { createStyles } from "tim-ui";

export const styles = stiboTheme =>
  createStyles({
    pageWrapper: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowX: "hidden",
    },
  });

export type TClasses = typeof styles;
