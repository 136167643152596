import { AttributeGroup, Image } from "../Product/types";
// import { TBusinessObjectMemberRenderer, BusinessObjectMember } from "../ProductViewer/BusinessObjects/types";

export interface ISection {
  name: string;
  order: number;
  members: Array<Members>;
  noMasonry?: boolean;
  isEmpty: boolean;
}

export enum MemberType {
  attributeGroup,
  carousel,
  productImage,
  businessObject,
}

export type Members = CarouselMember | AttributeGroupMember | ProductImageMember; // | BusinessObjectMember;

export interface IUngroupedSection {
  name: string;
  members: AttributeGroupMember[];
}

export class CarouselMember implements Member<Image[]> {
  type = MemberType.carousel;

  constructor(public value: Image[]) {
    this.value = value;
  }
}

export class ProductImageMember implements Member<Image> {
  type = MemberType.productImage;

  constructor(public value: Image) {
    this.value = value;
  }
}

export class AttributeGroupMember implements Member<AttributeGroup> {
  type = MemberType.attributeGroup;
  constructor(public value: AttributeGroup) {
    this.value = value.withChildGroups(value.childGroups.filter(group => group.hasAttributes() || group.hasChildren()));
  }
}

export interface Member<T> {
  type: MemberType;
  value: T;
}

export interface AttributesGroupBySections {
  [key: string]: AttributeGroup[] | undefined;
}

export type TAttributeGroupMemberRenderer = ({
  member,
  index,
}: {
  member: AttributeGroupMember;
  index: number;
}) => JSX.Element;

export type TCarouselMemberRenderer = ({ member, index }: { member: CarouselMember; index: number }) => JSX.Element;

export type TProductImageMemberRenderer = ({
  member,
  index,
}: {
  member: ProductImageMember;
  index: number;
}) => JSX.Element;

export interface MemberRenderers {
  [MemberType.attributeGroup]: TAttributeGroupMemberRenderer;
  [MemberType.carousel]: TCarouselMemberRenderer;
  [MemberType.productImage]: TProductImageMemberRenderer;
  // [MemberType.businessObject]: TBusinessObjectMemberRenderer;
}
