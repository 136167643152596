import { StandardProps } from "@material-ui/core";
import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStiboStyles,
  withStiboStyles,
} from "tim-ui";
import classNames from "classnames";
const isProduction = process.env.NODE_ENV === "production";
const isStandalone = process.env.BUILD_VARIANT === "standalone";

export type WaitScreenClasses = "waitScreen";

const styles = (stiboTheme): StyleRules<WaitScreenClasses> =>
  createStyles({
    waitScreen: {
      display: "block",
    },
  });

interface WaitScreenProps
  extends StandardProps<
    React.SelectHTMLAttributes<HTMLElement>,
    WaitScreenClasses
  > {}

const WaitScreen: React.FC<
  WaitScreenProps & WithStiboStyles<WaitScreenClasses>
> = (props) => {
  const { classes } = props;

  const waitScreenClassNames = classNames("waitscreen", classes.waitScreen);

  return (
    <>
      {isProduction && !isStandalone ? (
        <div className={waitScreenClassNames} />
      ) : (
        <div style={{ height: "600px" }} className={waitScreenClassNames}>
          loading...
        </div>
      )}
    </>
  );
};

export default withStiboStyles(styles)(WaitScreen);
