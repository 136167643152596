import * as React from "react";
import Masonry, { MasonryOptions } from "react-masonry-component";
import { WithStiboStyles, withStiboStyles } from "tim-ui";
import { styles, TClasses } from "./MasonryLayout.styles";

interface MasonryLayoutProps {
  children;
}

class MasonryLayout extends React.Component<MasonryLayoutProps & WithStiboStyles<TClasses>> {
  constructor(props) {
    super(props);
  }

  options: MasonryOptions = {
    transitionDuration: 0,
  };

  render() {
    const { classes, children } = this.props;
    return (
      <Masonry options={this.options} enableResizableChildren>
        {children.map((child: any, index: string) => (
          <div className={classes.masonryPanel} key={index}>
            {child}
          </div>
        ))}
      </Masonry>
    );
  }
}

export default withStiboStyles(styles)(MasonryLayout);
