import * as React from "react";
import { WithTranslation } from "tim-bridge";
import { WithStiboStyles, withStiboStyles, StyleRules, createStyles, Tooltip } from "tim-ui";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { noSelectableText } from "../../../../../common/classes";
import { nsTranslate } from "../../../../../i18n";
import PopupContainer from "./PopupContainer";
import PathNodeItem from "./PathNodeItem";
import { ShowAlertSeverity } from "tim-bridge/types";

const styles = (stiboTheme): StyleRules<PathBaseClassName> => {
  return createStyles({
    container: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "12px",
      lineHeight: "12px",
      color: stiboTheme.palette.black[300],
      cursor: "pointer",
      display: "inline-block",
      ...noSelectableText,
    },
    divcontainer: {
      display: "flex",
    },
    separator: {
      float: "left",
      marginTop: "10px",
      marginRight: "8px",
      color: stiboTheme.palette.black[200],
      "&:active": {
        color: stiboTheme.palette.navigation[500],
      },
      "&:focus": {
        color: stiboTheme.palette.navigation[500],
      },
      "&:hover": {
        color: stiboTheme.palette.black[300],
      },
      "&:disabled": {
        cursor: "default",
        color: stiboTheme.palette.black[50],
      },
    },
  });
};

export type PathBaseClassName = "container" | "divcontainer" | "separator";

export class PathStore {
  transformed: string;
  raw: string;
  nodes: string[];
  parentNodes: string[];
  canSplitParentNodes: boolean;
  canNavigatePath: boolean;
  private divider = " ▸ ";
  private ancestorsLevel = 3;
  private levelsCounter = 0;
  private nodeCounter = 0;
  private separator = "/";

  constructor(path: string, canNavigatePath: boolean) {
    this.raw = path;
    if (path.indexOf("\t") !== -1) {
      this.separator = "\t";
    }
    this.nodes = this.removeSlashes(this.removeLastChild(path));
    this.transformed = this.addDots(this.replaceSlashes(this.removeLastChild(path)));
    this.canNavigatePath = canNavigatePath;
  }

  private removeSlashes = path => {
    let allNodes = path.split(this.separator);
    let remainingNodes = new Array();
    let nodesArray = allNodes.slice(-3);
    this.setParentNodes(allNodes, nodesArray);
    for (let count = 0; count < nodesArray.length; count++) {
      remainingNodes.push([this.nodeCounter, nodesArray[count]]);
      this.nodeCounter++;
    }
    return remainingNodes;
  };

  private setParentNodes = (allNodes, nodesArray) => {
    let allParentNodes = new Array();
    if (allNodes.length > 3) {
      this.canSplitParentNodes = true;
      let parentNodesArray = allNodes.slice(0, allNodes.indexOf(nodesArray[0]));
      for (let count = 0; count < parentNodesArray.length; count++) {
        allParentNodes.push([this.nodeCounter, parentNodesArray[count]]);
        this.nodeCounter++;
      }
      this.parentNodes = allParentNodes;
    } else {
      this.canSplitParentNodes = false;
    }
  };

  private removeLastChild = (path: string) => {
    const index = path.lastIndexOf(this.separator);
    return path.substring(0, index);
  };

  private replaceSlashes = path => {
    let displayablePath = "";

    const length = path.length;

    for (let i = 0; i < length; i++) {
      if (path[i] === this.separator) {
        this.levelsCounter++;
        displayablePath = displayablePath + this.divider;
      } else {
        displayablePath = displayablePath + path[i];
      }
    }

    return displayablePath;
  };

  private addDots = path => {
    const amount = this.ancestorsLevel - 1;
    let displayablePath = path;

    if (this.levelsCounter > amount) {
      const l = this.levelsCounter - amount;

      for (let i = 0; i < l; i++) {
        const index = displayablePath.indexOf(this.divider);
        displayablePath = displayablePath.slice(index + 1, displayablePath.length);
      }

      displayablePath = `... ${displayablePath}`;
    }

    return displayablePath;
  };
}

interface PathProps {
  path: string;
  canNavigatePath: boolean;
  onNodeClick(nodeIndex: number, nodeTitle: string);
  onPathClick(severity: ShowAlertSeverity, headline: string, message: string);
}

interface PathInnerProps extends PathProps, WithTranslation, WithStiboStyles<PathBaseClassName> {}

class Path extends React.Component<PathInnerProps> {
  counter = 0;

  onCopied = () => {
    const { onPathClick } = this.props;
    onPathClick("info", "", "Path copied to clipboard");
  };

  onNodeClick = (nodeIndex: number, nodeTitle: string) => {
    const { onNodeClick } = this.props;
    onNodeClick(nodeIndex, nodeTitle);
  };

  render() {
    const { classes } = this.props as PathProps & WithStiboStyles<PathBaseClassName>;
    const path = new PathStore(this.props.path, this.props.canNavigatePath);
    return (
      <div>
        {path.canNavigatePath ? (
          <div className={classes.divcontainer}>
            {path.canSplitParentNodes ? (
              <PopupContainer parentNodes={path.parentNodes} onNodeClick={this.onNodeClick} />
            ) : (
              <div />
            )}
            <div className={classes.divcontainer}>
              {path.nodes.map((nodeprop, index, nodes) => {
                let divider = index < nodes.length - 1;
                return (
                  <PathNodeItem
                    index={index}
                    title={nodeprop[1]}
                    id={parseInt(nodeprop[0], 10)}
                    showDivider={divider}
                    onClick={this.onNodeClick}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <Tooltip title={path.raw} placement="top">
            <CopyToClipboard text={path.raw} onCopy={this.onCopied}>
              <div className={classes.container} id={"ProductSummaryPath"}>
                {path.transformed}
              </div>
            </CopyToClipboard>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default nsTranslate(withStiboStyles(styles)(Path));
