import { createStyles } from "tim-ui";

export type TClasses = typeof styles;

export const styles = stiboTheme => {
  const padding = 4 * stiboTheme.layout.gridPoint;
  return createStyles({
    container: {
      paddingTop: "25px",
      paddingLeft: `${padding}px`,
      paddingRight: `${padding}px`,
      paddingBottom: "25px",
    },
  });
};
