import * as React from "react";
import Query from "react-apollo/Query";
import {
  withBridgeCallbacks,
  WithBridgeCallbacks,
  WithGraphClient,
  withGraphqlEndpoint,
  withSelection,
  WithSelection,
  withStepQueryParams,
  WithStepQueryParams,
} from "tim-bridge";
import { WithStiboStyles } from "tim-ui";
import SufficiencyLoading from "./SufficiencyLoading";
import SufficiencyOverview, { SufficiencyOverviewClasses, SufficiencyOverviewProps } from "./SufficiencyOverview";
import fetchAllSufficiencies from "../screen/ProductDetailsScreen/ProductSummary/graphql/queries/sufficiency";
import { SufficiencyResponse } from "../screen/ProductDetailsScreen/ProductSummary/graphql/types";

const SufficiencyOverviewWrapper: React.FC<
  SufficiencyOverviewProps & { isUpdating?: boolean } & WithStiboStyles<SufficiencyOverviewClasses> &
    Partial<WithGraphClient> &
    Partial<WithStepQueryParams> &
    Partial<WithSelection> &
    Partial<WithBridgeCallbacks>
> = props => {
  const { client, selection, stepQueryParams, bridgeCallbacks, sufficiencies, isUpdating, ...rest } = props;

  if (sufficiencies) {
    return <SufficiencyOverview sufficiencies={sufficiencies} {...rest} />;
  }

  return isUpdating ? (
    <SufficiencyLoading {...rest} />
  ) : (
    <Query
      client={client as any}
      query={fetchAllSufficiencies()}
      variables={{
        ...stepQueryParams,
        nodeType: selection && selection.__typename,
        stepId: selection && selection.stepId,
      }}
      fetchPolicy={"network-only"}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <SufficiencyLoading {...rest} />;
        }

        if (error) {
          bridgeCallbacks
            ? bridgeCallbacks.showAlert(
                "error",
                "Failed to load sufficiency data for product id: " + selection!.stepId,
                error.message
              )
            : console.error(error);
          return null;
        }

        const sufficiencyResults = (data as SufficiencyResponse).sufficiencies.sufficiency.allSufficiencies;

        return <SufficiencyOverview sufficiencies={sufficiencyResults} {...rest} />;
      }}
    </Query>
  );
};

const sufficiencyOverviewWithSelection = withSelection(SufficiencyOverviewWrapper);
const sufficiencyOverviewWithBridgeCallbacks = withBridgeCallbacks(sufficiencyOverviewWithSelection);
const sufficiencyOverviewWithStepQueryParams = withStepQueryParams(sufficiencyOverviewWithBridgeCallbacks);
const sufficiencyOverviewWithGraphClient = withGraphqlEndpoint("/sufficiency-graphql")(
  sufficiencyOverviewWithStepQueryParams
);

export default sufficiencyOverviewWithGraphClient as React.FC<SufficiencyOverviewProps & { isUpdating?: boolean }>;
