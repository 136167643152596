import * as React from "react";
import { createStyles, withStiboStyles, WithStiboStyles } from "tim-ui";
import { MULTIVALUE_SEPARATOR } from "../../../common/valueUtils";

const styles = () =>
  createStyles({
    root: {
      paddingLeft: 0,
    },
    element: {
      display: "inline-block",
      wordBreak: "break-word",
      "&:before": {
        content: `"\\2022"`,
        margin: "0px 5px",
        fontSize: "16px",
      },
      "&:first-child:before": {
        display: "none",
        content: `""`,
      },
    },
  });

export const BulletValueListBase: React.SFC<{ value: string } & WithStiboStyles<typeof styles>> = props => {
  const { value, classes } = props;
  const rows = value.split(MULTIVALUE_SEPARATOR).map((value, index) => {
    return (
      <li key={index} className={classes.element}>
        {value}
      </li>
    );
  });

  return <ul className={classes.root}>{rows}</ul>;
};

const BulletValueList = withStiboStyles(styles)(BulletValueListBase);

export { BulletValueList };
