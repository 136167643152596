import gql from "graphql-tag";
import { evaluationDetails, sufficiencyResult } from "../fragments/sufficiency";

const fetchAllSufficiencies = () => {
  return gql`
    query nodeSufficiencies(
      $stepId: String!
      $nodeType: NodeType!
      $contextStepId: String!
      $workspaceStepId: String!
    ) {
      sufficiencies: sufficiencyNode(
        stepId: $stepId
        nodeType: $nodeType
        contextStepId: $contextStepId
        workspaceStepId: $workspaceStepId
      ) {
        stepId
        title
        sufficiency {
          allSufficiencies {
            ...Result
          }
        }
      }
    }
    ${evaluationDetails}
    ${sufficiencyResult}
  `;
};

export default fetchAllSufficiencies;
