import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SeverityLevel } from "../../../graphql/query/types";

export type SufficiencyMessageClasses = "container" | "indicator" | "sufficiencymessage";

const styles = (stiboTheme): StyleRules<SufficiencyMessageClasses> =>
  createStyles({
    container: {
      display: "flex",
      color: stiboTheme.palette.black[400],
      lineHeight: "20px",
      marginBottom: "8px",
    },
    indicator: {
      minWidth: "8px",
      width: "8px",
      minHeight: "8px",
      height: "8px",
      borderRadius: "2px",
      margin: "3px 16px 0 16px",
    },
    sufficiencymessage: {
      "&:before": {
        margin: "8px",
        fontSize: "16px",
        content: `"\\2022"`,
        color: stiboTheme.palette.black[400],
      },
    },
  });

interface SufficiencyMessageProps
  extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyMessageClasses> {
  severityLevel?: SeverityLevel;
  message: string;
}

const SufficiencyMessage: React.FC<SufficiencyMessageProps & WithStiboStyles<SufficiencyMessageClasses>> = props => {
  const { severityLevel, message, className: classNameProp, classes, ...rest } = props;

  const className = classNames(classes.container, classNameProp);
  let newMessage = message.split("\n").map((str, index) => <div key={index}>{str}</div>);
  return (
    <span className={className} data-qa="sufficiency-message" {...rest}>
      <div className={classes.sufficiencymessage} />
      <div>{newMessage}</div>
    </span>
  );
};

export default withStiboStyles(styles)(SufficiencyMessage);
