import gql from "graphql-tag";
import { errorList } from "tim-bridge";
import { collectionEdges } from "./fragment/attribute.fragment";
import { attributeGroup } from "./fragment/attributeGroup.fragment";

const errorListFragment = errorList();

export const updateNode = gql`
  mutation($input: UpdateNodeInput!, $updatedAttributesIds: [String!]!, $stepId: String!) {
    updateNode(input: $input) {
      ... on UpdateNodePayload {
        ...errorList
        ... on UpdateNodeOutput {
          updated {
            stepId
            values(attributeStepIds: $updatedAttributesIds) {
              ...Edges
            }
          }
        }
      }
    }
  }
  ${collectionEdges}
  ${attributeGroup}
  ${errorListFragment}
`;

export const calculateSufficiency = gql`
  mutation calculateSufficiency(
    $stepId: String!
    $nodeType: NodeType!
    $contextStepId: String!
    $workspaceStepId: String!
  ) {
    sufficiencies: calculateSufficiency(
      stepId: $stepId
      nodeType: $nodeType
      contextStepId: $contextStepId
      workspaceStepId: $workspaceStepId
    ) {
      stepId
    }
  }
`;

export const fetchProduct = () => {
  return gql`
    query product($contextStepId: String!, $workspaceStepId: String!, $nodeType: StepNodeTypes, $stepId: String!) {
      product: stepNode(
        contextStepId: $contextStepId
        workspaceStepId: $workspaceStepId
        nodeType: $nodeType
        stepId: $stepId
      ) {
        stepId
        title
        ... on Product {
          parent {
            title
          }
          path
          values {
            ...Edges
          }
          approvalState
          currentRevision
          lastUpdateDate
        }
        __typename
      }
    }
    ${collectionEdges}
    ${attributeGroup}
  `;
};

export const fetchAttributeListOfValues = () => {
  return gql`
    query product(
      $contextStepId: String!
      $workspaceStepId: String!
      $nodeType: StepNodeTypes
      $stepId: String!
      $lovAttributeId: String!
    ) {
      product: stepNode(
        contextStepId: $contextStepId
        workspaceStepId: $workspaceStepId
        nodeType: $nodeType
        stepId: $stepId
      ) {
        ... on Product {
          validLoVValues(attributeStepId: $lovAttributeId) {
            edges {
              node {
                value
                unit {
                  stepId
                  title
                }
              }
            }
          }
        }
      }
    }
  `;
};
