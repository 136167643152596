import * as React from "react";
import { withStiboStyles, WithStiboStyles, createStyles } from "tim-ui";
import classnames = require("classnames");

const styles = stiboTheme =>
  createStyles({
    outter: {
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
    },
    white: {
      backgroundColor: "#ffffff",
    },
    transparent: {
      backgroundColor: "#ffffff",
      opacity: 0.4,
      zIndex: 10000,
    },
  });

export type classes = "outter" | "white" | "transparent";

class Overlay extends React.Component<
  { withBackground?: boolean; withTransparentBackground?: boolean; size?: number } & WithStiboStyles<classes>
> {
  render() {
    const { classes, withBackground, withTransparentBackground = false, children } = this.props;

    return (
      <div
        className={classnames(classes.outter, {
          [classes.white]: withBackground,
          [classes.transparent]: withTransparentBackground,
        })}
      >
        {children}
      </div>
    );
  }
}

export default withStiboStyles(styles)(Overlay);
