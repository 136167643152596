import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "tim-bridge";
import { createStyles, StyleRules, Tooltip, WithStiboStyles, withStiboStyles } from "tim-ui";
import SufficiencyIndicator from "./SufficiencyIndicator";
import SufficiencyPanel from "./panel/SufficiencyPanel";
import { SufficiencyResult } from "../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyOverviewClasses =
  | "container"
  | "overview"
  | "messageSummary"
  | "separator"
  | "indicator"
  | "overflow"
  | "ellipsis";

const styles = (stiboTheme): StyleRules<SufficiencyOverviewClasses> =>
  createStyles({
    container: {
      position: "relative",
      minWidth: "320px",
      padding: "0 16px 0 16px",
      textAlign: "center",
      lineHeight: "32px",
      height: "32px",
      borderRadius: "4px",
      cursor: "pointer",
      overflow: "hidden",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: stiboTheme.palette.grey[200],
        "& $ellipsis": {
          backgroundColor: stiboTheme.palette.grey[200],
        },
      },
    },
    overview: {},
    messageSummary: {
      color: stiboTheme.palette.black[400],
      paddingRight: "24px",
    },
    separator: {
      marginTop: "11px",
      marginBottom: "11px",
      height: "10px",
      borderRadius: "2px",
      borderRight: `1px solid ${stiboTheme.palette.black[200]}`,
    },
    indicator: {
      marginLeft: "24px",
    },
    overflow: {
      marginRight: "12px",
    },
    ellipsis: {
      position: "absolute",
      backgroundColor: stiboTheme.colors.white,
      padding: "0 6px 0 6px",
      borderRadius: "4px",
      right: 0,
      zIndex: 1,
      "&:hover": {
        backgroundColor: stiboTheme.palette.grey[200],
      },
    },
  });

export interface SufficiencyOverviewProps
  extends StandardProps<React.SelectHTMLAttributes<HTMLSpanElement>, SufficiencyOverviewClasses> {
  sufficiencies?: SufficiencyResult[];
  dataQa?: string;
}

const SufficiencyOverview: React.FC<SufficiencyOverviewProps & WithStiboStyles<SufficiencyOverviewClasses>> = props => {
  const { sufficiencies, dataQa, className, classes, ...rest } = props;

  const { t } = useTranslation();

  if (!sufficiencies || (sufficiencies && sufficiencies.length === 0)) {
    return null;
  }

  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showEllipsis, setShowEllipsis] = useState<boolean>(false);

  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const handleResize = () => {
      const el = ref.current;

      if (el) {
        setShowEllipsis(el.offsetWidth < el.scrollWidth);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let totalAmountOfSufficiencyMessages = 0;
  const sufficiencyIndicators: JSX.Element[] = [];
  sufficiencies.forEach(sufficiency => {
    const {
      severityLevel,
      evaluationDetails: { metricName: title, score, messageHolders = [] },
    } = sufficiency;

    sufficiencyIndicators.push(
      <SufficiencyIndicator
        key={title}
        className={classes.indicator}
        severityLevel={severityLevel}
        title={title}
        score={score}
      />
    );

    messageHolders.forEach(messageHolder => {
      totalAmountOfSufficiencyMessages += messageHolder.messages.length;
    });
  });

  const handleMouseDown = (e: React.MouseEvent) => {
    setShowPanel(true);
  };

  const combinedClassName = classNames(classes.container, className);
  const overview = classNames(classes.overview, {
    [classes.overflow]: showEllipsis,
  });

  return (
    <React.Fragment>
      <Tooltip
        title={`${t("sufficiencyOverview.tooltip", {
          defaultValue: "Open Sufficiency Inspector",
        })}`}
        placement="bottom-start"
      >
        <span
          className={combinedClassName}
          ref={ref}
          onMouseDown={handleMouseDown}
          data-qa={dataQa || "sufficiency-overview"}
          {...rest}
        >
          <span className={overview}>
            {totalAmountOfSufficiencyMessages > 0 && (
              <React.Fragment>
                <b className={classes.messageSummary} data-qa="sufficiency-message-summary">
                  {totalAmountOfSufficiencyMessages} messages
                </b>
                <span className={classes.separator} />
              </React.Fragment>
            )}
            {sufficiencyIndicators}
          </span>

          {showEllipsis && (
            <Tooltip
              title={`${t("sufficiencyOverview.overflowTooltip", {
                defaultValue: `Total number of sufficiency indications`,
              })}: ${sufficiencyIndicators.length}`}
              placement="bottom"
            >
              <span className={classes.ellipsis} data-qa="sufficiency-overview-ellipsis">
                &#8230;
              </span>
            </Tooltip>
          )}
        </span>
      </Tooltip>
      <SufficiencyPanel sufficiencies={sufficiencies} visible={showPanel} setVisible={setShowPanel} />
    </React.Fragment>
  );
};

export default withStiboStyles(styles)(SufficiencyOverview);
