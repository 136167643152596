import * as React from "react";
import { TableRow, TableCell, createStyles, WithStiboStyles, withStiboStyles } from "tim-ui";
import { generateTestID } from "../../../common/generateTestID";
import { ValueBaseProps } from "./ValueBase";

const styles = () =>
  createStyles({
    tableRow: {},
    tableCellTopAlignedLabel: {
      paddingLeft: "0!important",
      paddingRight: "0!important",
    },
    tableCellTopAligned: {
      wordBreak: "break-word",
      wordWrap: "break-word",
      whiteSpace: "pre-line",
      "& ul": { paddingLeft: "21px" }, // need to override webui all selector
      paddingLeft: "0!important",
      paddingRight: "0!important",
    },
    tableRowMultiLinesKey: {},
    tableRowMultiLinesValue: {},
  });

const LongSingleValueBase: React.SFC<ValueBaseProps & WithStiboStyles<typeof styles>> = props => {
  const { id, label, value, classes } = props;

  return (
    <>
      <TableRow className={classes.tableRow} id={generateTestID(`TableRow_${label}_${id}`)}>
        <TableCell
          className={classes.tableCellTopAlignedLabel}
          colSpan={2}
          id={generateTestID(`TableCellLabel_${label}`)}
        >
          {label}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCellTopAligned} colSpan={2} id={generateTestID(`TableCellValue_${label}`)}>
          {value}
        </TableCell>
      </TableRow>
    </>
  );
};

const LongSingleValue = withStiboStyles(styles)(LongSingleValueBase);

export { LongSingleValue, LongSingleValueBase };
