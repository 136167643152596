import * as React from "react";
import { observer } from "mobx-react";
import { IProductEditorStore } from "../productEditorStore";
import Toolbar, { ToolbarProps } from "../../Toolbar/Toolbar";
import { WithTranslation } from "tim-bridge";
import { nsTranslate } from "../../../../../i18n";
import Spinner from "../../Spinner/Spinner";

interface ToolbarReactiveWrapperProps {
  store: IProductEditorStore;
  classes;
  isProductReloading: boolean;
}

@observer
class ToolbarReactiveWrapper extends React.Component<ToolbarReactiveWrapperProps & WithTranslation> {
  render() {
    const { store, isProductReloading } = this.props;

    const canSave = store.form ? store.form.canSave : false;
    const toolbarProps: ToolbarProps = {
      rightSideActions: [
        {
          label: this.props.t("cancel.message", { defaultValue: "Cancel" }),
          onClick: store.onEditClosed,
          color: "secondary",
          className: this.props.classes.cancelButton,
        },
        {
          label: isProductReloading ? <Spinner size={20} /> : this.props.t("save.message", { defaultValue: "Save" }),
          onClick: store.onSave,
          color: "secondary",
          disabled: !canSave,
        },
        {
          label: this.props.t("saveAndClose.message", { defaultValue: "Save And Close" }),
          onClick: store.onSaveAndClose,
          color: "primary",
          disabled: !canSave,
        },
      ],
    };

    return <Toolbar rightSideActions={toolbarProps.rightSideActions} />;
  }
}

export default nsTranslate(ToolbarReactiveWrapper);
