import classNames from "classnames";
import * as React from "react";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { ElementNames, extractElementId, generateTableOfContentsItemId } from "../../../../../common/dataUtils";
import { mediaQueries } from "../../../../../common/mediaQueries";
import { AttributeGroup } from "../../Product/types";
import { AttributeGroupMember, ISection, Members } from "../../Sections/types";
import { validMemberFilter } from "../productAttributesEditor/ProductAttributesEditor";
import { productSummaryHeight, toolbarHeight } from "../productAttributesEditor/ProductAttributesEditor.styles";
import { webkitScrollbar } from "../Scrollbar/webkitScrollbar";

export type ProductTableOfContentsClasses =
  | "wrapper"
  | "container"
  | "webkitScrollbar"
  | "productTableOfContentsHeader"
  | "productTableOfContentsMessage"
  | "productTableOfContentsSectionList"
  | "productTableOfContentsSectionItem"
  | "productTableOfContentsSectionContainer"
  | "productTableOfContentsSectionLink"
  | "productTableOfContentsAttributeGroupList"
  | "productTableOfContentsAttributeGroupItem"
  | "productTableOfContentsAttributeGroupContainer"
  | "productTableOfContentsAttributeGroupLink";

const PRODUCT_TABLE_OF_CONTENTS_LEVEL_MAX: number = 10;

const OVERLAY_BOTTOM_SPACING: number = 5;

const styles = (stiboTheme): StyleRules<ProductTableOfContentsClasses> =>
  createStyles({
    wrapper: {
      padding: 0,
      margin: "0 2em 0 0",
      minWidth: "20rem",
      maxWidth: "30rem",
      flexGrow: 1,
      flexShrink: 4,
      paddingRight: "8px", // looks better when scroll bar is visible
      [mediaQueries.columns1]: {
        display: "none",
      },
      [mediaQueries.columns2]: {
        display: "none",
      },
    },
    container: {
      position: "fixed",
      margin: "0 2em 0 0",
      minWidth: "20rem",
      maxWidth: "30rem",
      padding: 0,
      paddingRight: "8px", // looks better when scroll bar is visible
      top: productSummaryHeight + toolbarHeight,
      height: `calc(100vh - ${productSummaryHeight}px - ${toolbarHeight}px - ${OVERLAY_BOTTOM_SPACING}px)`,
      overflowY: "hidden",
    },
    webkitScrollbar: {
      "&:hover": {
        overflowY: "auto",
        ...webkitScrollbar,
      },
    },
    productTableOfContentsHeader: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "10px",
      height: "12px",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "1.5px",
      color: stiboTheme.palette.black[400],
      margin: "0 0 2.8em 0",
      padding: 0,
    },
    productTableOfContentsMessage: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.87)",
    },
    productTableOfContentsSectionList: {
      listStyleType: "none",
      margin: 0,
      paddingLeft: 0,
    },
    productTableOfContentsSectionItem: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[300],
      width: "100%",
      marginBottom: "48px",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    productTableOfContentsSectionContainer: {
      width: "100%",
      paddingBottom: "0.3em",
      marginBottom: "1em",
      borderBottom: `1px solid ${stiboTheme.palette.black[50]}`,
    },
    productTableOfContentsSectionLink: {
      color: stiboTheme.palette.black[300],
      textDecoration: "none",
      cursor: "pointer",
    },
    productTableOfContentsAttributeGroupList: {
      listStyleType: "none",
      marginTop: "1em",
      paddingLeft: "1.2em",
    },
    productTableOfContentsAttributeGroupItem: {
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif;",
      fontSize: "14px",
      lineHeight: "16px",
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: "normal",
      color: stiboTheme.palette.black[400],
      width: "100%",
    },
    productTableOfContentsAttributeGroupContainer: {
      width: "100%",
      marginBottom: "1em",
    },
    productTableOfContentsAttributeGroupLink: {
      color: "rgba(0, 0, 0, 0.87)",
      textDecoration: "none",
      cursor: "pointer",
    },
  });

export interface ProductTableOfContentsProps {
  sections?: ISection[];
  members?: Members[];
  // scrollPosition?: number;
  container: React.Ref<HTMLDivElement>;
}

class ProductTableOfContents extends React.Component<
  ProductTableOfContentsProps & WithStiboStyles<ProductTableOfContentsClasses>
> {
  handleTableOfContentsItemClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void | undefined {
    const targetElement: HTMLAnchorElement = event.target as HTMLAnchorElement;
    const tableOfContentsItemId: string = targetElement.id;
    const elementId: string = extractElementId(tableOfContentsItemId);
    const element: HTMLElement | null = document.getElementById(elementId);
    element && element.scrollIntoView();
  }

  renderAttributeGroups = (
    attributeGroups: AttributeGroup[],
    classes: Record<ProductTableOfContentsClasses, string>
  ) => {
    return (
      <ul className={classes.productTableOfContentsAttributeGroupList}>
        {attributeGroups.map((attributeGroup: AttributeGroup, index: number) => {
          const { title, level, childGroups } = attributeGroup;
          const itemId: string = generateTableOfContentsItemId(ElementNames.attributeGroup, title);

          return (
            <li key={index} className={classes.productTableOfContentsAttributeGroupItem}>
              <div className={classes.productTableOfContentsAttributeGroupContainer}>
                <a
                  id={itemId}
                  onClick={this.handleTableOfContentsItemClick}
                  className={classes.productTableOfContentsAttributeGroupLink}
                >
                  {title}
                </a>
                {childGroups &&
                  childGroups.length > 0 &&
                  level <= PRODUCT_TABLE_OF_CONTENTS_LEVEL_MAX &&
                  this.renderAttributeGroups(childGroups, classes)}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  renderSectionMembers = (members: Members[], classes: Record<ProductTableOfContentsClasses, string>) => {
    const validMembers = members.filter(validMemberFilter);

    return (
      <ul className={classes.productTableOfContentsAttributeGroupList}>
        {validMembers &&
          validMembers.map((member: AttributeGroupMember, index: number) => {
            const { title } = member.value;
            const itemId: string = generateTableOfContentsItemId(ElementNames.attributeGroup, title);
            const { childGroups } = member.value;

            return (
              <li key={index} className={classes.productTableOfContentsAttributeGroupItem}>
                <div className={classes.productTableOfContentsAttributeGroupContainer}>
                  <a
                    id={itemId}
                    onClick={this.handleTableOfContentsItemClick}
                    className={classes.productTableOfContentsAttributeGroupLink}
                  >
                    {title}
                  </a>
                </div>
                {childGroups && this.renderAttributeGroups(childGroups, classes)}
              </li>
            );
          })}
      </ul>
    );
  };

  renderSections = (sections: ISection[], classes: Record<ProductTableOfContentsClasses, string>) => {
    return (
      <ul className={classes.productTableOfContentsSectionList}>
        {sections &&
          sections.map(section => {
            const { name, members } = section;
            const itemId: string = generateTableOfContentsItemId(ElementNames.section, name);

            return (
              <li key={name} className={classes.productTableOfContentsSectionItem}>
                <div className={classes.productTableOfContentsSectionContainer}>
                  <a
                    id={itemId}
                    onClick={this.handleTableOfContentsItemClick}
                    className={classes.productTableOfContentsSectionLink}
                  >
                    {name}
                  </a>
                </div>
                {members && this.renderSectionMembers(members, classes)}
              </li>
            );
          })}
      </ul>
    );
  };

  render() {
    const { sections, members, classes } = this.props;
    (window as any).toc = this.props.container;
    return (
      <section className={classes.wrapper}>
        <div className={classNames(classes.container, classes.webkitScrollbar)} ref={this.props.container}>
          <h2 className={classes.productTableOfContentsHeader}>TABLE OF CONTENTS</h2>
          {sections && sections.length > 0 ? (
            this.renderSections(sections, classes)
          ) : members ? (
            this.renderSectionMembers(members, classes)
          ) : (
            <p className={classes.productTableOfContentsMessage}>No product data...</p>
          )}
        </div>
      </section>
    );
  }
}

export default withStiboStyles(styles)(ProductTableOfContents);
