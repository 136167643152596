import { action, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { createStyles, withStiboStyles, WithStyles } from "tim-ui";
import { BrokenImageIcon } from "../icons/brokenImageIcon/BrokenImageIcon";
import classnames = require("classnames");
import * as ReactDOM from "react-dom";

export type ThumbnailClasses =
  | "selected"
  | "preview"
  | "imageContainer"
  | "mediaPreviewTile"
  | "brokenImage"
  | "brokenImageContainer";

export interface ThumbnailProps {
  onSelected: (index) => void;
  index: number;
  url: string;
  title: string;
  isSelected?: boolean;
}

export const styles = (stiboTheme: any) =>
  createStyles({
    imageContainer: {
      width: "56px",
      height: "56px",
      margin: "8px 16px 8px 16px",
      boxSizing: "border-box",
      verticalAlign: "middle",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mediaPreviewTile: {
      maxWidth: "48px",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      cursor: "pointer",
      userSelect: "none",
      margin: 0,
      outline: 0,
      "&:active": {
        outline: 0,
      },
    },
    selected: {
      border: "none",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: stiboTheme.colors.navigation,
      borderRadius: "6px",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    },
    preview: {
      display: "flex",
      justifyContent: "center",
      flexFlow: "row wrap",
    },
    brokenImage: {
      height: "35%",
      width: "35%",
      fill: stiboTheme.palette.grey[300],
    },
    brokenImageContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

@observer
class Thumbnail extends React.Component<WithStyles<ThumbnailClasses> & ThumbnailProps> {
  @observable isValidImage: boolean = true;
  ref: React.RefObject<HTMLImageElement> = React.createRef();
  @action
  onImageLoadError = () => {
    this.isValidImage = false;
  };

  brokenImage = () => (
    <div
      className={classnames(this.props.classes.brokenImageContainer, {
        [this.props.classes.selected]: this.props.isSelected,
      })}
      title="File type not supported"
    >
      <BrokenImageIcon className={this.props.classes.brokenImage} />
    </div>
  );

  componentWillReceiveProps(props: WithStyles<ThumbnailClasses> & ThumbnailProps) {
    if (props.isSelected) {
      const element = this.ref && ReactDOM.findDOMNode && (ReactDOM.findDOMNode(this.ref.current) as any);
      element.focus();
    }
  }

  render() {
    const { title, url, classes, index, isSelected } = this.props;

    return (
      <div
        onClick={() => this.props.onSelected(index)}
        className={classnames(classes.imageContainer, { [classes.selected]: isSelected })}
      >
        {this.isValidImage ? (
          <img
            className={classes.mediaPreviewTile}
            src={url}
            title={title}
            key={index}
            id={`Carousel_thumbnail_${index}`}
            height={100}
            onError={this.onImageLoadError}
            tabIndex={0}
            ref={this.ref}
          />
        ) : (
          this.brokenImage()
        )}
      </div>
    );
  }
}

export default withStiboStyles(styles)(Thumbnail);
