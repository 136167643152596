import React = require("react");
import { CardContent, WithStiboStyles } from "tim-ui";

import AttributesTableCard from "./AttributesTableCard";
import { OverlineHeader } from "./OverlineHeader";
import { attributeGroupCardClasses } from "./AttributeGroupCard";
import { ElementNames, generateElementId } from "../../common/dataUtils";
import { AttributeGroup } from "../screen/ProductDetailsScreen/Product/types";

const SecondLevelGroup = (
  props: { attributeGroup: AttributeGroup } & WithStiboStyles<attributeGroupCardClasses>
): JSX.Element => {
  const { classes, attributeGroup } = props;
  const { stepId, title, attributes } = attributeGroup;
  const elementId: string = generateElementId(ElementNames.attributeGroup, title);

  return (
    <div id={elementId}>
      <CardContent className={classes.content} id={`CardContent_AttributeGroupCard_${stepId}`}>
        <OverlineHeader className={classes.tileHeading} title={title} />

        {attributeGroup.hasAttributes() ? (
          <AttributesTableCard key={stepId} title={""} titleStyle={classes.lev1Heading} attributes={attributes} />
        ) : null}

        {attributeGroup.hasChildren() &&
          attributeGroup.level > 0 &&
          attributeGroup.childGroups.map(group => {
            const { stepId, title, attributes } = group;

            return (
              <AttributesTableCard
                key={stepId}
                title={title}
                titleStyle={classes.lev1Heading}
                attributes={attributes}
              />
            );
          })}
      </CardContent>
    </div>
  );
};

export { SecondLevelGroup };
