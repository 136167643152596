import * as React from "react";
import * as ReactDOM from "react-dom";
import App, { AppWithHotReload } from "./components/app/AppMain";

const getDevComponent = require("tim-bridge").getDevComponent;

export const run = (componentName, parameters, isStandalone) => {
  const AppWrapper = getDevComponent(
    isStandalone ? App : AppWithHotReload,
    {
      env: process.env,
      ...parameters,
    },
    componentName
  );

  ReactDOM.render(<AppWrapper />, document.getElementById(AppWrapper.containerId));
};
