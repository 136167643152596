import * as React from "react";
import { useEffect, useRef } from "react";
import { Close, createStyles, IconButton, withStiboStyles, WithStyles } from "tim-ui";
import ProductImage from "../productImage/ProductImage";
import { Image } from "../screen/ProductDetailsScreen/Product/types";
import { CarouselAction, CarouselState } from "./Carousel";
import InvalidPicturePlaceholder from "./InvalidPicturePlaceholder";
import Thumbnail from "./Thumbnail";

export type ImageViewerOverlayBaseClassName = "overlay" | "close" | "preview" | "image" | "invalidPicturePlaceholder";

export const styles = (stiboTheme: any) =>
  createStyles({
    overlay: {
      display: "flex",
      zIndex: 1000,
      outline: "none",
      position: "fixed",
      top: 0,
      right: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: stiboTheme.palette.grey[100],
      boxSizing: "border-box",
      padding: "64px 32px 32px 32px",
    },
    close: {
      position: "fixed",
      top: "16px",
      right: "32px",
      width: "32px",
      height: "32px",
    },
    preview: {
      boxSizing: "border-box",
      width: "376px",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "flex-start",
      overflowY: "auto",
    },
    image: {
      borderRadius: "8px",
      height: "100%",
      "& > img": {
        maxHeight: "100%",
      },
    },
    invalidPicturePlaceholder: {},
  });

interface ImageViewerOverlayProps {
  pictures: Image[];
  state: CarouselState;
  dispatch: React.Dispatch<CarouselAction>;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageViewerOverlay: React.FC<WithStyles<ImageViewerOverlayBaseClassName> & ImageViewerOverlayProps> = props => {
  const { pictures, state, dispatch, setShowOverlay, classes } = props;

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref && ref.current && ref.current.focus();
  }, []);

  const setPictureHandler = index => {
    dispatch({ type: "PICTURE_NO", value: index });
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const pictureLength = pictures.length;

    switch (event.keyCode) {
      case HotKeys.Escape:
        setShowOverlay(false);
        break;
      case HotKeys.LeftArrow:
        dispatch({
          type: "PICTURE_NO",
          value: (state.pictureNo - 1 + pictureLength) % pictureLength,
        });
        break;
      case HotKeys.RightArrow:
        dispatch({
          type: "PICTURE_NO",
          value: (state.pictureNo + 1) % pictureLength,
        });
        break;
    }
  };

  const picture = pictures[state.pictureNo];
  const pictureOk = state.pictureOk;

  return (
    <div className={classes.overlay} onKeyUp={handleKeyUp} tabIndex={0} ref={ref}>
      <IconButton className={classes.close} onClick={() => setShowOverlay(false)} aria-label="Close">
        <Close />
      </IconButton>
      <div className={classes.preview} id={`Carousel-overlay_thumbnails`}>
        {pictures.map((picture, index) => (
          <Thumbnail
            isSelected={index === state.pictureNo}
            onSelected={setPictureHandler}
            key={index}
            url={picture.thumbnail}
            title={picture.title}
            index={index}
          />
        ))}
      </div>
      {picture && pictureOk ? (
        <ProductImage image={picture} className={classes.image} isFullScreenPreview={true} />
      ) : (
        <InvalidPicturePlaceholder className={classes.invalidPicturePlaceholder} />
      )}
    </div>
  );
};

enum HotKeys {
  Escape = 27,
  LeftArrow = 37,
  RightArrow = 39,
}

export default withStiboStyles(styles)(ImageViewerOverlay);
