import * as React from "react";
import {
  withBridgeCallbacks,
  WithBridgeCallbacks,
  withSelection,
  withGraphqlEndpoint,
  useTranslation,
} from "tim-bridge";
import { hot } from "react-hot-loader";
import { ApolloClient } from "apollo-client";
import { GET_WORKSPACE_DIFF, APPROVE_WORKSPACE_DIFF } from "./queries";
import {
  ApproveResult,
  ValueData,
  PartObject,
  referenceType,
  attributeLinkType,
  dataContainerType,
  nameType,
  attributeType,
  workspaceType,
  validPartObjectTypes,
  isRefTypeData,
} from "./Types";
import { ThemeProvider, Button, Tooltip } from "tim-ui";
import {
  partition,
  convertTitle,
  convertValue,
  getGroups,
  canBeApproved,
  convertTypeTitle,
} from "./utils";
import { PartObjectsSection } from "./PartObjectsSection";
import styled from "styled-components";
import ApproveResultComponent from "./ApproveResultComponent";
import { WithTranslation, nsTranslate } from "../i18n";
import WaitScreen from "./WaitScreen";
import SubHeading from "./SubHeading";

const isProduction = process.env.NODE_ENV === "production";
const isStandalone = process.env.BUILD_VARIANT === "standalone";

export interface MainParams {
  currentUser: string;
}

interface PartialApprovalProps extends WithTranslation {
  workspaceId: string;
  contextId: string;
  selection: {
    stepId: string;
    __typename: string;
    title: string;
  };
  client: ApolloClient<any>;
  parameters: MainParams;
}

const StyledDialogDiv = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Noto Sans", Ubuntu, "Droid Sans", "Helvetica Neue", Helvetica,
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  height: 100%;
  word-break: break-word;
  overflow: none;
  display: flex;
  flex-direction: column;
`;

const ContentDiv = styled.div`
  background-color: #f6f6f6;
  flex: 1;
  overflow-y: auto;
`;

const StyledApproveResultComponent = styled(ApproveResultComponent)`
  margin-top: 32px;
  margin-left: 64px;
  margin-right: 64px;
`;

const StyledPartObjectsSection = styled(PartObjectsSection)`
  border: 1px solid #979797;
  border-radius: 4px;
  margin-bottom: 32px;
  margin-top: 32px;
  margin-left: 64px;
  margin-right: 64px;
  background-color: white;
`;

const HeaderDiv = styled.div`
  height: 64px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  outline: none;
`;

const NoDataDiv = styled.div`
  margin: 32px auto;
  width: 50%;
`;

const FooterDiv = styled.div`
  border-top: 1px solid lightgray;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
`;

const SelectedLabel = styled.label`
  margin-right: 32px;
`;

const SelectedNumber = styled.label`
  font-weight: bold;
`;

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: normal;
  margin: 0;
  max-width: 1600px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  float: right;
`;

const ApproveButtonContainer = styled.div`
  float: right;
`;

const PartialApprovalDialogInternal: React.FC<
  PartialApprovalProps & Partial<WithBridgeCallbacks>
> = (props) => {
  const {
    selection: { stepId, __typename, title },
    client,
    contextId,
    bridgeCallbacks,
    parameters: { currentUser },
    t,
  } = props;

  // const { t, ready } = useTranslation(["partialapprove"]);

  const dialogTitle: string = t("PartialApprovalDialog.title", {
    defaultValue: "Review & Approve Changes for ",
  });
  const myChangesTitle: string = t("PartialApprovalDialog.myChangesTitle", {
    defaultValue: "My Unapproved Changes",
  });

  const myNoChangesTitle: string = t("PartialApprovalDialog.myNoChangesTitle", {
    defaultValue: "You have made no changes to be approved",
  });

  const otherNoChangesTitle: string = t(
    "PartialApprovalDialog.otherNoChangesTitle",
    {
      defaultValue: "No unapproved changes have been made by other users",
    }
  );

  const noDataTitle: string = t("PartialApprovalDialog.noDataTitle", {
    defaultValue:
      "No changes available to be approved for one of three reasons:",
  });
  const noDataReason1: string = t("PartialApprovalDialog.noDataReason1", {
    defaultValue:
      "unapproved data is privilege restricted for the logged-in user",
  });
  const noDataReason2: string = t("PartialApprovalDialog.noDataReason2", {
    defaultValue: "unapproved data exists in a different context",
  });
  const noDataReason3: string = t("PartialApprovalDialog.noDataReason3", {
    defaultValue:
      "unapproved changes were made in tables, product overrides, or in the workbench",
  });

  const noDataLabelTooltip: string = t(
    "PartialApprovalDialog.noDataLabelTooltip",
    {
      defaultValue:
        "Either no changes have been made, or no changes for which the user is privileged are available for approval",
    }
  );

  const otherChangesTitle: string = t(
    "PartialApprovalDialog.otherChangesTitle",
    {
      defaultValue: "Unapproved Changes Made by Others ",
    }
  );
  const numberOfSelectedLabel: string = t(
    "PartialApprovalDialog.numberOfSelectedLabel",
    {
      defaultValue: "changes selected",
    }
  );
  const cancelButtonLabel: string = t(
    "PartialApprovalDialog.cancelButtonLabel",
    {
      defaultValue: "Cancel",
    }
  );
  const approveButtonLabel: string = t(
    "PartialApprovalDialog.approveButtonLabel",
    {
      defaultValue: "Approve",
    }
  );

  let updateSelected = (ids: string[], s: boolean) => {
    if (s) {
      let toBeselected = ids.filter((id) => !selected.includes(id));
      setSelected(selected.concat(toBeselected));
    } else {
      let toBeUnselected = ids.filter((id) => selected.includes(id));
      setSelected(selected.filter((id) => !toBeUnselected.includes(id)));
    }
  };

  const updateRefPartsAprovability = (pos: PartObject<ValueData>[]) => {
    const badRefTypes: string[] = [];
    pos.forEach((po) => {
      if (isRefTypeData(po.data)) {
        if (!canBeApproved(po)) {
          badRefTypes.push(po.title);
        }
      }
    });

    pos.forEach((po) => {
      if (isRefTypeData(po.data)) {
        po.approvable = !badRefTypes.includes(po.title);
      }
    });
  };

  const query = () => {
    setSystemError(undefined);
    setWaiting(true);
    client
      .query({
        query: GET_WORKSPACE_DIFF,
        variables: {
          contextStepId: contextId,
          nodeType: __typename,
          stepId: stepId,
        },
        fetchPolicy: "network-only",
      })
      .then((value: any) => {
        const filteredDifs = value.data.workspaceDifferences.filter(
          (wd: { __typename: string }) =>
            validPartObjectTypes.includes(wd.__typename)
        );

        const partObjects: PartObject<ValueData>[] = filteredDifs.map(
          (raw: any) => ({
            id: raw.id,
            responsibleUser: raw.responsibleUser,
            approvable: raw.approvable,
            changed: new Date(raw.changed),
            typeID: raw.__typename || "---",
            typeTitle: convertTypeTitle(raw),
            title: convertTitle(raw),
            data: convertValue(raw),
            groups: getGroups(raw),
          })
        );

        const [myPartObjects, otherPartObjects] = partition(
          partObjects,
          (po: PartObject<ValueData>) =>
            currentUser === undefined
              ? po.responsibleUser === "STEPSYS"
              : po.responsibleUser === currentUser
        );

        updateRefPartsAprovability(partObjects);
        !data &&
          updateSelected(
            myPartObjects
              .filter((po: PartObject<ValueData>) => po.approvable)
              .map((po: PartObject<ValueData>) => po.id),
            true
          );
        setData(partObjects);
        setMyData(myPartObjects);
        setOtherData(otherPartObjects);
        setWaiting(false);
      })
      .catch((reason) => {
        setWaiting(false);
        console.log("Error fetching workspace differences: " + reason);
        setSystemError(reason);
      });
  };

  const [data, setData] = React.useState<PartObject<ValueData>[]>(undefined);

  const [myData, setMyData] = React.useState<PartObject<ValueData>[]>([]);
  const [otherData, setOtherData] = React.useState<PartObject<ValueData>[]>([]);
  const [waiting, setWaiting] = React.useState<boolean>(true);
  const [systemError, setSystemError] = React.useState<String>(undefined);
  const [selected, setSelected] = React.useState<string[]>([]);
  const errorRef = React.useRef(null);
  const contentRef = React.useRef(null);

  const handleEscape = (e: { keyCode: number }) => {
    if (e.keyCode === 27) {
      bridgeCallbacks.closeDialog();
      window.removeEventListener("keydown", handleEscape, false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape, false);
    query();
  }, []);

  interface ApproveResultProps {
    data: { approveDifferences: ApproveResult };
  }
  const approveDifferences = (diffIDs: Array<string>) => {
    console.log(diffIDs);
    setSystemError(undefined);
    client
      .mutate({
        mutation: APPROVE_WORKSPACE_DIFF,
        variables: {
          contextStepId: contextId,
          input: {
            node: stepId,
            nodeType: __typename,
            differences: diffIDs,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((value: ApproveResultProps) => {
        const errors = value.data.approveDifferences.errors;
        const noErrors = errors ? errors.length === 0 : true;
        const missingDependencies =
          value.data.approveDifferences.missingDependencies;
        const noMissing = missingDependencies
          ? missingDependencies.length === 0
          : true;
        setWaiting(false);
        if (noErrors && noMissing) {
          if (isProduction && !isStandalone) {
            bridgeCallbacks.approve();
          } else {
            query();
          }
        } else {
          setApproveResult(value.data.approveDifferences);
          if (
            value.data.approveDifferences.missingDependencies.length > 0 ||
            value.data.approveDifferences.errors.length > 0
          ) {
            errorRef.current.focus();
            errorRef.current.scrollIntoView();
          }
          query();
        }
      })
      .catch((reason) => {
        setWaiting(false);
        console.log("Error approving workspace differences: " + reason);
        setSystemError(reason);
      });
  };

  const [approveResult, setApproveResult] =
    React.useState<ApproveResult>(undefined);

  const partionedPartObjects = partition(data, (po: PartObject<ValueData>) =>
    currentUser === undefined
      ? po.responsibleUser === "STEPSYS"
      : po.responsibleUser === currentUser
  );

  const getTitleString: () => string = () => {
    const idInPar = "(" + stepId + ")";
    if (title === undefined || title === idInPar) {
      return dialogTitle + idInPar;
    } else {
      return dialogTitle + title + " " + idInPar;
    }
  };

  return (
    <ThemeProvider>
      <StyledDialogDiv>
        <HeaderDiv tabIndex="10000">
          <Tooltip title={getTitleString()}>
            <H1>{getTitleString()}</H1>
          </Tooltip>
        </HeaderDiv>
        <ContentDiv tabIndex="10001" ref={contentRef}>
          <div
            className="unapprovedChanges"
            style={{ overflow: "auto", height: "100%" }}
          >
            <div style={{ visibility: "hidden" }} ref={errorRef} />
            {data && data.length === 0 ? (
              <NoDataDiv id="noDataSection">
                <SubHeading title={noDataTitle} />
                <ul style={{ listStyle: "inside", padding: 0 }}>
                  <li>{noDataReason1}</li>
                  <li>{noDataReason2}</li>
                  <li>{noDataReason3}</li>
                </ul>
              </NoDataDiv>
            ) : undefined}
            {systemError ? undefined : <label>{systemError}</label>}
            {waiting ? (
              <WaitScreen />
            ) : (
              <>
                <StyledApproveResultComponent
                  tabIndex="10000"
                  approveResult={approveResult}
                  currentObjectTitle={title ? title : stepId}
                  linkResolver={bridgeCallbacks.followLink}
                />
                <StyledPartObjectsSection
                  id="myChangesSection"
                  collapserConfig={{ collapsible: false, collapsed: false }}
                  selectionConfig={{
                    selectionCallback: updateSelected,
                    selected: selected,
                  }}
                  title={myChangesTitle}
                  noChangesString={myNoChangesTitle}
                  diffs={myData}
                />
                <StyledPartObjectsSection
                  id="otherChangesSection"
                  collapserConfig={{
                    collapsible: partionedPartObjects[1].length > 0,
                    collapsed: partionedPartObjects[0].length > 0,
                  }}
                  selectionConfig={{
                    selectionCallback: updateSelected,
                    selected: selected,
                  }}
                  title={otherChangesTitle}
                  noChangesString={otherNoChangesTitle}
                  diffs={otherData}
                />
              </>
            )}
          </div>
        </ContentDiv>
        <FooterDiv>
          <ButtonContainer>
            <SelectedLabel>
              <SelectedNumber id="selectedNumber">
                {selected.length}
              </SelectedNumber>{" "}
              {numberOfSelectedLabel}
            </SelectedLabel>
            <Button
              title={t(
                "partialapprove:PartialApprovalDialog.cancelButtonText",
                { defaultValue: "Click to close dialog without approving" }
              )}
              style={{ marginRight: "8px" }}
              onClick={() => bridgeCallbacks.closeDialog()}
            >
              {cancelButtonLabel}
            </Button>
            <ApproveButtonContainer
              title={
                selected.length === 0
                  ? t(
                      "partialapprove:PartialApprovalDialog.disabledOKButtonText",
                      {
                        defaultValue:
                          "Select at least one item to enable approval button",
                      }
                    )
                  : t(
                      "partialapprove:PartialApprovalDialog.enabledOKButtonText",
                      {
                        defaultValue: "Click to approve selected items",
                      }
                    )
              }
            >
              <Button
                disabled={selected.length === 0}
                onClick={() => approveDifferences(selected)}
              >
                {approveButtonLabel}
              </Button>
            </ApproveButtonContainer>
          </ButtonContainer>
        </FooterDiv>
      </StyledDialogDiv>
    </ThemeProvider>
  );
};

const PartialApprovalDialog = nsTranslate(
  withGraphqlEndpoint("/graphqlv2")(
    withSelection(withBridgeCallbacks(PartialApprovalDialogInternal))
  )
);

export const AppWithHotReload = hot(module)(PartialApprovalDialog);
export default PartialApprovalDialog;
