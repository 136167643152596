import * as React from "react";
import { registerComponent as registerBridgeComponent } from "tim-bridge";
const config = require("../tim-meta.json");

interface ComponentsGroup {
  [componentName: string]: React.ComponentType;
}

function mapLegacyIds(meta) {
  const ids = Array.isArray(meta.legacyIds) ? meta.legacyIds : [];
  if (ids.length < 1) {
    return {};
  }
  return ids.reduce(
    (acc, id) => ({
      ...acc,
      [id]: meta.componentName,
    }),
    {}
  );
}

function groupMetaArray(meta) {
  meta = !Array.isArray(meta) ? [meta] : meta;
  return meta.reduce(
    (acc, m) => ({
      meta: {
        ...acc.meta,
        [m.componentName]: m,
      },
      legacyIds: {
        ...acc.legacyIds,
        ...mapLegacyIds(m),
      },
    }),
    {
      meta: {},
      legacyIds: {},
    }
  );
}

const registerComponent = (
  Component: React.ComponentType,
  meta: { packageName: string; componentName: string },
  version?: string
) => {
  return registerBridgeComponent(meta.packageName, meta.componentName, Component, version);
};

const registerComponents = (components: ComponentsGroup, version?: string) => {
  const { meta, legacyIds } = groupMetaArray(config);
  Object.keys(components).forEach(componentName => {
    const mappedName = meta.hasOwnProperty(componentName) ? componentName : legacyIds[componentName];

    if (mappedName) {
      const componentMeta = meta[mappedName];
      registerComponent(components[componentName], componentMeta, version);
    } else {
      throw new ReferenceError(`Component "${componentName} is not declared in tim-meta."`);
    }
  });
};

export { registerComponent, registerComponents, ComponentsGroup };
