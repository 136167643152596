import { Moment } from "moment";
import * as React from "react";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import { SufficiencyOverview } from "../../../sufficiency";
import ApprovalState from "./components/ApprovalState";
import LastUpdateDate from "./components/LastUpdateDate";
import Path from "./components/Path";
import Title from "./components/Title";
import { ApprovalStates } from "../Product/types";
import ThumbnailWithFallback from "./components/ThumbnailWithFallback";
import { ShowAlertCallback, graphUtils } from "tim-bridge";

export type ProductSummaryClasses = "container" | "summary" | "sufficiency";

const styles = (stiboTheme): StyleRules<ProductSummaryClasses> =>
  createStyles({
    container: {
      clear: "both",
      fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
      fontSize: "12px",
      backgroundColor: "#FFFFFF",
      color: stiboTheme.palette.black[300],
      padding: "32px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    summary: {
      marginRight: "24px",
    },
    sufficiency: {
      alignSelf: "flex-end",
      marginTop: "24px",
    },
  });

interface ProductSummaryProps {
  title: string;
  id: string;
  path: string;
  currentRevision: string;
  approvalState: ApprovalStates;
  approvalDate?: Moment;
  lastUpdateDate?: Moment;
  hasSufficiencyLicence: boolean;
  thumbnailURL?: string;
  onThumbnailClick?: () => void;
  className?: string;
  canNavigatePath: boolean;
  onNodeClick?: (nodeIndex: number, nodeTitle: string) => void;
  onPathClick?: ShowAlertCallback;
}

class ProductSummary extends React.Component<
  ProductSummaryProps & { isUpdating?: boolean } & WithStiboStyles<ProductSummaryClasses>,
  any
> {
  render() {
    const {
      title,
      id,
      currentRevision,
      approvalState,
      approvalDate,
      lastUpdateDate,
      classes,
      path,
      className,
      isUpdating,
      hasSufficiencyLicence,
      thumbnailURL,
      onThumbnailClick,
      canNavigatePath,
      onNodeClick,
      onPathClick,
    } = this.props;
    return (
      <section className={className || classes.container}>
        <div style={{ display: "flex" }}>
          <ThumbnailWithFallback src={thumbnailURL} onClick={onThumbnailClick} />
          <div className={classes.summary}>
            <Path
              {...({ path } as any)}
              canNavigatePath={canNavigatePath}
              onNodeClick={onNodeClick}
              onPathClick={onPathClick}
            />
            <Title>{graphUtils.parseLtGtTags(title)}</Title>
            <div>
              {id} · <ApprovalState approvalState={approvalState} approvalDate={approvalDate} /> ·{" "}
              <span id={"ProductSummaryCurrentRevision"}>{currentRevision}</span>
              {lastUpdateDate && " · "}
              {lastUpdateDate && <LastUpdateDate lastUpdateDate={lastUpdateDate} />}
            </div>
          </div>
        </div>
        {hasSufficiencyLicence && <SufficiencyOverview className={classes.sufficiency} isUpdating={isUpdating} />}
      </section>
    );
  }
}

export default withStiboStyles(styles)(ProductSummary);
