import { createStyles } from "tim-ui";

export const styles = stiboTheme => {
  const padding = 4 * stiboTheme.layout.gridPoint;

  return createStyles({
    header: {
      height: "24px",
      marginBottom: "10px",
      color: stiboTheme.palette.black[300],
      float: "left",
    },
    wrapper: {
      borderTop: "1px solid",
      paddingTop: "25px",
      marginBottom: "25px",
      borderColor: stiboTheme.palette.black[50],
      clear: "both",
    },
    container: {
      paddingLeft: `${padding}px`,
      paddingRight: `${padding}px`,
    },
    exceptionMessage: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
      fontSize: "20px",
      fontWeight: 500,
      textAlign: "center",
      paddingTop: "72px",
      color: stiboTheme.palette.black[300],
    },
  });
};

export type TClasses = typeof styles;
