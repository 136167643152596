import * as React from "react";
import { Tooltip } from "tim-ui";
import { nsTranslate } from "../../../../../i18n";
import { TagProps, ApprovedProps, ApprovedInnerProps } from "../types";

const PartlyApproved = (props: ApprovedInnerProps & TagProps) => {
  const { date, t } = props;
  const tag = (
    <span className={props.className} id={"ProductSummaryPartlyApproved"}>
      {t("partlyApproved.text", { defaultValue: "Unapproved changes" })}
    </span>
  );

  return date ? (
    <Tooltip
      title={`${t("partlyApproved.tooltip", { defaultValue: "Last Approved on" })} ${date.format("LLLL")}`}
      placement="top"
    >
      {tag}
    </Tooltip>
  ) : (
    tag
  );
};

export default nsTranslate(PartlyApproved);
