import { VALIDATE_VALUE_QUERY, ValidationMessage } from "@stibo/value-components";
import { ApolloQueryResult } from "apollo-client/core/types";
import gql from "graphql-tag";
import { FetchResult } from "react-apollo/Mutation";
import { TimGraphApollo } from "tim-bridge";
import { ProductTO, ProductLovTO } from "../../Product/types";
import { AppConfig } from "../../types";
import { AttributeValueBase, convertAttributeValueToAttributeValueInput, convertValueToValueInput } from "../formStore";
import { fetchProduct, updateNode, fetchAttributeListOfValues, calculateSufficiency } from "./queries/queries";
import { StepMaintainableNodeTypes, UpdateNodeInput, UpdateNodePayload, UpdateSufficiencies } from "./types";
import { batchAttributeGroupRequest } from "../../ProductViewer/client/client";
import { createBatchQueries } from "../../ProductViewer/client/queries/optimizedQueries";
import { fixAttributeValueTags } from "../../../../../common/tagsUtils";

interface EditClientProps {
  graphqlClient: TimGraphApollo;
  sufficiencyClient: TimGraphApollo;
  config: AppConfig;
}

export type NodeUpdateMutation = (props: UpdateNodeProps) => Promise<FetchResult<UpdateNodePayload>>;
export type ProductForEditorRequest = () => Promise<ApolloQueryResult<ProductResponse>>;
export interface IEditClient {
  nodeUpdate: NodeUpdateMutation;
  fetchProduct: ProductForEditorRequest;
  fetchListOfValues: (lovAttributeId: string) => Promise<ApolloQueryResult<ProductLovResponse>>;
  validate: (value) => Promise<any>;
  calculateSufficiency: () => Promise<FetchResult<UpdateSufficiencies>>;
  batchAttributeGroup: batchAttributeGroupRequest;
}

export type ProductResponse = {
  product: ProductTO;
};

export type ProductLovResponse = {
  product: ProductLovTO;
};

interface UpdateNodeProps {
  values: AttributeValueBase[];
}

class EditClient implements IEditClient {
  queryParams;
  constructor(private props: EditClientProps) {
    this.queryParams = props.config;
  }

  nodeUpdate = (props: UpdateNodeProps) => {
    const { graphqlClient: client, config } = this.props;
    const input: UpdateNodeInput = {
      stepId: config.stepId,
      contextStepId: config.contextID,
      workspaceStepId: config.workspaceID,
      nodeType: StepMaintainableNodeTypes.Product,
      values: props.values.map(value => convertAttributeValueToAttributeValueInput(fixAttributeValueTags(value))),
    };
    const updatedAttributesIds: String[] = props.values.map(v => v.attribute.stepId);

    return client.mutate({
      mutation: updateNode,
      variables: {
        input,
        updatedAttributesIds,
        stepId: input.stepId,
      },
    });
  };

  fetchProduct = () => {
    const { graphqlClient: client, config } = this.props;

    return client.query({
      fetchPolicy: "network-only",
      query: fetchProduct(),
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
        nodeType: config.nodeType,
        stepId: config.stepId,
      },
    });
  };

  fetchListOfValues = (lovAttributeId: string) => {
    const { graphqlClient: client, config } = this.props;

    return client.query({
      fetchPolicy: "cache-first",
      query: fetchAttributeListOfValues(),
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
        nodeType: config.nodeType,
        stepId: config.stepId,
        lovAttributeId: lovAttributeId,
      },
    });
  };

  validate = value => {
    const { graphqlClient: client, config } = this.props;

    return client.query<{ validateValue: Array<ValidationMessage> }>({
      fetchPolicy: "cache-first",
      query: gql`
        ${VALIDATE_VALUE_QUERY}
      `,
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
        attributeStepId: value.attribute.stepId,
        value: convertValueToValueInput(fixAttributeValueTags(value)),
      },
    });
  };

  calculateSufficiency = () => {
    const { sufficiencyClient: client, config } = this.props;

    return client.mutate({
      mutation: calculateSufficiency,
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
        nodeType: config.nodeType,
        stepId: config.stepId,
      },
    });
  };

  batchAttributeGroup = (id: string[]) => {
    const { graphqlClient: client, config } = this.props;
    return client.query({
      query: createBatchQueries(id),
      variables: {
        contextStepId: config.contextID,
        workspaceStepId: config.workspaceID,
      },
    });
  };
}

export { EditClient };
