import * as React from "react";
import { StyleRules, createStyles, withStiboStyles, WithStiboStyles, Card, Modal as ModalMUI } from "tim-ui";
import classnames = require("classnames");

const styles = (stiboTheme): StyleRules<ModalClasses> =>
  createStyles({
    container: {
      width: "100vw",
      height: "100vh",
      outline: "none",
    },
    centerWrapper: {
      alignSelf: "center",
    },
  });

interface ModalProps {
  children;
  isOpen: boolean;
  className?: string;
  onClose: () => void;
}

type ModalClasses = "container" | "centerWrapper";

const Modal = (props: ModalProps & WithStiboStyles<ModalClasses>) => {
  const { children, isOpen, onClose, classes, className = "" } = props;
  return (
    <ModalMUI open={isOpen} onBackdropClick={onClose}>
      <div className={classes.container} onClick={onClose}>
        <div className={classnames(classes.centerWrapper, className)} onClick={e => e.stopPropagation()}>
          <Card>{children}</Card>
        </div>
      </div>
    </ModalMUI>
  );
};

export default withStiboStyles(styles)(Modal);
