import * as React from "react";
import { WithStiboStyles, withStiboStyles, createStyles } from "tim-ui";
import { WithTranslation } from "tim-bridge";
import WithoutSections from "./WithoutSections";
import { IProductViewerStore } from "./ProductViewerStore";
import { Sections } from "../Sections/Sections";
import { observer } from "mobx-react";
import Toolbar, { ToolbarProps } from "../Toolbar/Toolbar";
import Spinner from "../Spinner/Spinner";
// import { IBusinessObjectsClient } from "./BusinessObjects/query/businessObjectsClient";
import { AppConfig } from "../types";
import ProductErrors from "../Errors/ProductErrors";
import { BridgeCallbacksProps } from "../ProductDetailsScreen";
import { RefObject } from "react";
import { nsTranslate } from "../../../../i18n";
import EnhancedMarketingContent from "../WebCollage/EnhancedMarketingContent";

export const styles = stiboTheme =>
  createStyles({
    pageWrapper: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowX: "hidden",
    },
  });

type Classes = "pageWrapper" | "exceptionMessage";

export type ProductViewerProps = {
  productViewerStore: IProductViewerStore;
  // businessObjectsClient?: IBusinessObjectsClient;
  onEditOpened: () => void;
  bridgeCallbacks?: BridgeCallbacksProps;
  config: AppConfig;
} & WithStiboStyles<Classes> &
  WithTranslation;

@observer
class ProductViewer extends React.Component<ProductViewerProps> {
  toolbarProps: ToolbarProps;
  store: IProductViewerStore;
  container: RefObject<HTMLDivElement>;
  constructor(props: ProductViewerProps) {
    super(props);

    (props.bridgeCallbacks && props.bridgeCallbacks.setDirty && props.bridgeCallbacks.setDirty(false)) ||
      console.log("No callback: setDirty!", false);

    this.toolbarProps = props.productViewerStore.isEditable
      ? {
          rightSideActions: [
            {
              label: props.t("edit.message", { defaultValue: "Edit" }),
              onClick: () => props.onEditOpened(),
              color: "secondary",
            },
          ],
        }
      : {
          rightSideActions: [],
        };
    this.container = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate() {
    this.container && this.container.current && this.container.current.focus();
  }

  render() {
    const { classes, config, productViewerStore } = this.props;
    const { sections: storeSections, ungroupedSection, members } = productViewerStore;
    const sections = storeSections ? storeSections : [];

    if (productViewerStore.isProductLoading) {
      return <Spinner />;
    }

    return (
      <ProductErrors
        stepId={config.stepId}
        nodeType={config.nodeType}
        isEmptyProduct={productViewerStore.isEmptyProduct}
        productTitle={(productViewerStore.product && productViewerStore.product.title) || ""}
        onEditModeOpen={this.props.onEditOpened}
        isProductNotFound={this.store && !this.store.isProductFound}
      >
        <div id="DetailScreen" className={classes.pageWrapper} tabIndex={0} ref={this.container}>
          <Toolbar rightSideActions={this.toolbarProps.rightSideActions} />
          {sections.length > 0 ? (
            <Sections sections={sections} ungroupedSection={ungroupedSection} />
          ) : (
            members && <WithoutSections members={members} />
          )}
          {config.isSyndigoAvailable && <EnhancedMarketingContent appConfig={config} />}
        </div>
      </ProductErrors>
    );
  }
}

export { ProductViewer };

export default withStiboStyles(styles)(nsTranslate(ProductViewer));
