import {
  AttributeValueBase,
  isAttributeMultiValue,
} from "../components/screen/ProductDetailsScreen/ProductEditor/formStore";
import { AttributeMultiValue, AttributeSingleValue } from "@stibo/value-components";

export function fixAttributeValueTags(value: AttributeValueBase): AttributeValueBase {
  return isAttributeMultiValue(value) ? fixMultiValueTags(value) : fixSingleValueTags(value);
}

function fixMultiValueTags(multiValue: AttributeMultiValue) {
  return {
    ...multiValue,
    values: multiValue.values.map(value => {
      return {
        ...value,
        value: fixTags(value.value),
      };
    }),
  };
}

function fixSingleValueTags(singleValue: AttributeSingleValue) {
  return {
    ...singleValue,
    value: fixTags(singleValue.value),
  };
}

export function fixTags(str: string) {
  str = str.replace(/ < /g, " <lt// ");
  str = str.replace(/ > /g, " <gt/> ");
  str = str.replace(/<lt\/\//g, "<lt/>");
  return str;
}
