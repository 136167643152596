import * as React from "react";
import { StyleRules, withStiboStyles, WithStiboStyles, createStyles, Button } from "tim-ui";

const styles = (stiboTheme): StyleRules<ToolbarClasses> =>
  createStyles({
    container: {
      width: "calc(100% - 64px)",
      height: "32px",
      padding: "8px 32px 8px 32px",
      "&:focus": { outline: "none" },
    },
    toolbarButton: {
      margin: "0 0 0 8px",
    },
    menuContainer: {
      position: "relative",
      display: "inline-block",
      verticalAlign: "top",
    },
    rightSideContainer: {
      float: "right",
    },
  });

type ToolbarClasses = "container" | "toolbarButton" | "menuContainer" | "rightSideContainer";

interface IToolbarAction {
  label: string | React.ReactElement<any>;
  onClick: () => void;
  color?: "inherit" | "primary" | "secondary" | "default";
  className?: string;
  disabled?: boolean;
}

export interface ToolbarProps {
  rightSideActions: IToolbarAction[];
}

class Toolbar extends React.Component<ToolbarProps & WithStiboStyles<ToolbarClasses>> {
  render() {
    const { classes, rightSideActions } = this.props;
    return (
      <div className={classes.container} id={"toolbar"}>
        <div className={classes.rightSideContainer}>
          <div className={classes.menuContainer}>
            {rightSideActions.map((action, i) => (
              <Button
                key={i}
                className={(action.className && action.className) || classes.toolbarButton}
                id={`toolbarAction_${action.label}`}
                onClick={action.onClick}
                color={action.color}
                disabled={action.disabled}
              >
                {" "}
                {action.label}{" "}
              </Button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withStiboStyles(styles)(Toolbar);
