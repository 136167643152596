import { AppWithComponentParams as ProductEditorComponent } from "./components/app/AppMain";
import { AppWithComponentParams as ProductSummaryComponent } from "./components/screen/ProductDetailsScreen/ProductSummary/App";
import { run } from "./runner";
import { registerComponents } from "./registrar";

const config = require("../tim-meta.json");
const parameters = require("../app-parameters.json");
const isProduction = process.env.NODE_ENV === "production";
const isStandalone = process.env.BUILD_VARIANT === "standalone";

if (isProduction && !isStandalone) {
  registerComponents(
    {
      ProductEditor: ProductEditorComponent,
      ProductSummary: ProductSummaryComponent,
    },
    process.env.PACKAGE_VERSION
  );
} else {
  run(config.packageName, parameters, isStandalone);
}
