const DEFAULT_ELEMENT_ID_SEPARATOR: string = "_";
const DEFAULT_TABLE_OF_CONTENTS_ITEM_PREFIX: string = "toc";

enum ElementNames {
  section = "section",
  attributeGroup = "attribute_group",
  attribute = "attribute",
}

const parseElementName = (elementName: string, separator: string = DEFAULT_ELEMENT_ID_SEPARATOR): string => {
  const parsedElementName: string = elementName.replace(/\s/g, separator);

  return parsedElementName;
};

const generateElementId = (
  elementType: string,
  elementName: string,
  separator: string = DEFAULT_ELEMENT_ID_SEPARATOR
): string => {
  const parsedElementName: string = parseElementName(elementName, separator);
  const elementId: string = `${elementType}${separator}${parsedElementName}`;

  return elementId;
};

const generateTableOfContentsItemId = (
  elementType: string,
  elementName: string,
  prefix: string = DEFAULT_TABLE_OF_CONTENTS_ITEM_PREFIX,
  separator: string = DEFAULT_ELEMENT_ID_SEPARATOR
): string => {
  const parsedElementName: string = parseElementName(elementName, separator);
  const itemId: string = `${prefix}${separator}${elementType}${separator}${parsedElementName}`;

  return itemId;
};

const extractElementId = (
  itemId: string,
  prefix: string = DEFAULT_TABLE_OF_CONTENTS_ITEM_PREFIX,
  separator: string = DEFAULT_ELEMENT_ID_SEPARATOR
): string => {
  const elementId: string = itemId.substr(prefix.length + separator.length);

  return elementId;
};

export {
  DEFAULT_ELEMENT_ID_SEPARATOR,
  DEFAULT_TABLE_OF_CONTENTS_ITEM_PREFIX,
  ElementNames,
  generateElementId,
  generateTableOfContentsItemId,
  extractElementId,
};
