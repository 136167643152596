import { createStyles, withStiboStyles, WithStiboStyles } from "tim-ui";
import * as React from "react";
import { TableRow, TableCell } from "tim-ui";
import { generateTestID } from "../../../common/generateTestID";
import { ValueBaseProps } from "./ValueBase";
import { MULTIVALUE_SEPARATOR } from "../../../common/valueUtils";

export const classNames = {
  tableRow: {},
  tableCellTopAlignedLabel: {
    paddingLeft: "0!important",
    paddingRight: "0!important",
  },
  tableCellTopAligned: {
    paddingLeft: "0!important",
    paddingRight: "0!important",
    wordBreak: "break-word",
    wordWrap: "break-word",
    "& ul": { paddingLeft: "21px" }, // need to override webui all selector
  },
  tableRowMultiLinesKey: {},
  tableRowMultiLinesValue: {},
  list: {},
  listElement: {},
};

const styles = () =>
  createStyles({
    ...(classNames as any),
    list: {
      paddingLeft: "1.5em",
    },
  });

export const LongMultiValueBase: React.SFC<ValueBaseProps & WithStiboStyles<typeof styles>> = props => {
  const { id, value, label, classes } = props;

  const labelKey = -1;

  const rows = value.split(MULTIVALUE_SEPARATOR).map((value, index) => {
    return (
      <li key={index} className={classes.listElement}>
        {value}
      </li>
    );
  });

  return (
    <>
      <TableRow className={classes.tableRow} key={labelKey}>
        <TableCell className={classes.tableCellTopAlignedLabel} colSpan={2}>
          {label}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRow} key={id}>
        <TableCell className={classes.tableCellTopAligned} colSpan={2} id={generateTestID(`TableCellValue_${label}`)}>
          <ul className={classes.list}>{rows}</ul>
        </TableCell>
      </TableRow>
    </>
  );
};

const LongMultiValue = withStiboStyles(styles)(LongMultiValueBase);

export { LongMultiValue };
