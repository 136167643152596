import { useCallback } from "react";
import { TypeaheadFn } from "@stibo/value-components";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { useStepQueryParams, useTimGraphClient } from "tim-bridge";

const SEARCH_LOV_QUERY = gql`
  query searchLovs(
    $contextStepId: String!
    $workspaceStepId: String!
    $lovStepId: String!
    $prefix: String!
    $maxCount: Int!
    $first: Int!
    $after: String
  ) {
    listOfValues(contextStepId: $contextStepId, workspaceStepId: $workspaceStepId, stepId: $lovStepId) {
      validValues(prefix: $prefix, maxResults: $maxCount, first: $first, after: $after) {
        edges {
          node {
            externalId
            value
            unit {
              stepId
              title
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export async function searchLovValues(
  client: ApolloClient<any>,
  contextStepId: string,
  workspaceStepId: string,
  lovStepId: string,
  prefix?: string,
  maxCount: number = 1000,
  first: number = 50,
  after?: number
) {
  return await client!.query({
    query: SEARCH_LOV_QUERY,
    variables: {
      contextStepId,
      workspaceStepId,
      prefix,
      lovStepId,
      maxCount,
      first,
      after: after && after.toString(),
    },
  });
}

export function useLovTypeahead(lovStepId: string): TypeaheadFn {
  const queryParams = useStepQueryParams();
  const client = useTimGraphClient().getDefaultClient();

  return useCallback(
    (prefix: string, maxCount?: number, first?: number, after?: number) => {
      return searchLovValues(
        client as any,
        queryParams.contextStepId,
        queryParams.workspaceStepId,
        lovStepId,
        prefix,
        maxCount,
        first,
        after
      ).then((result: any) => {
        return {
          result: result.data.listOfValues.validValues.edges,
          hasNextPage: result.data.listOfValues.validValues.pageInfo.hasNextPage,
        };
      });
    },
    [lovStepId]
  );
}
