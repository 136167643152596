import gql from "graphql-tag";

export const node = gql`
  query node($contextStepId: String!, $workspaceStepId: String!, $nodeType: StepNodeTypes, $stepId: String!) {
    node: stepNode(
      contextStepId: $contextStepId
      workspaceStepId: $workspaceStepId
      nodeType: $nodeType
      stepId: $stepId
    ) {
      stepId
      title
      ... on Product {
        path
        approvalState
        currentRevision
        lastUpdateDate
        approvalDate
      }
      ... on Entity {
        path
        approvalState
        currentRevision
        lastUpdateDate
        approvalDate
      }
    }
  }
`;
