export interface EvaluationDetails extends WithSeverityLevel {
  metricId: string;
  metricName: string;
  metricType?: string;
  calculationDate: string;
  score: string;
  showAsBoolean: boolean;
  messageHolders?: MessageHolders[];
  evaluationDetails?: EvaluationDetails[];
}

export interface MessageHolders {
  dataType: {
    stepId: string;
    title: string;
  };
  messages: Message[];
}

export interface Message extends WithSeverityLevel {
  message: string;
}

export interface WithSeverityLevel {
  severityLevel: SeverityLevel;
}

export enum SeverityLevel {
  Success = "SUCCESS",
  Warning = "WARNING",
  Error = "ERROR",
}

export const SECTION_MEMBERSHIP_ATTRIBUTE_ID = "AttributeGroupSection";
