import * as React from "react";
import classNames from "classnames";
import { StandardProps } from "@material-ui/core";
import { createStyles, StyleRules, WithStiboStyles, withStiboStyles } from "tim-ui";
import SufficiencyIndicator, { compareSeverityLevel } from "../SufficiencyIndicator";
import { SufficiencyResult } from "../../screen/ProductDetailsScreen/ProductSummary/graphql/types";

export type SufficiencyPanelSectionClasses =
  | "container"
  | "header"
  | "indicator"
  | "description"
  | "messageSection"
  | "message"
  | "metricSection"
  | "metric"
  | "metricIndicator"
  | "metricSuccess"
  | "metricWarning"
  | "metricError";

const styles = (stiboTheme): StyleRules<SufficiencyPanelSectionClasses> =>
  createStyles({
    container: {
      color: stiboTheme.palette.black[300],
    },
    header: {
      borderBottom: `1px solid ${stiboTheme.palette.black[100]}`,
      paddingBottom: "2px",
    },
    indicator: {
      color: stiboTheme.palette.black[400],
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      fontFamily: "Noto Serif",
      marginBottom: "8px",
      "& span": {
        padding: "3px 16px 3px 16px",
      },
    },
    description: {
      margin: 0,
      marginTop: 20,
      lineHeight: "20px",
    },
    messageSection: {
      paddingTop: "20px",
    },
    message: {},
    metricSection: {
      display: "flex",
      flexDirection: "column",
    },
    metric: {
      color: stiboTheme.palette.black[400],
      display: "flex",
      justifyContent: "space-between",
      marginTop: "16px",
      fontSize: "14px",
      whiteSpace: "pre-wrap",
    },
    metricIndicator: {
      color: stiboTheme.palette.black[400],
      padding: "1px 12px 1px 12px",
    },
    metricSuccess: {
      backgroundColor: stiboTheme.palette.success[50],
      border: `solid 1px ${stiboTheme.palette.success[500]}`,
    },
    metricWarning: {
      backgroundColor: stiboTheme.palette.warning[50],
      border: `solid 1px ${stiboTheme.palette.warning[700]}`,
    },
    metricError: {
      backgroundColor: stiboTheme.palette.danger[50],
      border: `solid 1px ${stiboTheme.palette.danger[500]}`,
    },
  });

interface SufficiencyPanelSectionProps
  extends StandardProps<React.SelectHTMLAttributes<HTMLElement>, SufficiencyPanelSectionClasses> {
  sufficiency: SufficiencyResult;
}

const SufficiencyPanelSection: React.FC<
  SufficiencyPanelSectionProps & WithStiboStyles<SufficiencyPanelSectionClasses>
> = props => {
  const {
    sufficiency: {
      severityLevel,
      description,
      evaluationDetails: { metricName: title, score, messageHolders, evaluationDetails },
    },
    className: classNameProp,
    classes,
    ...rest
  } = props;

  const metricIndicatorClasses = {
    indicator: classes.metricIndicator,
    success: classes.metricSuccess,
    warning: classes.metricWarning,
    error: classes.metricError,
  };

  let metricList: JSX.Element[] = [];
  if (evaluationDetails && evaluationDetails.length > 0) {
    evaluationDetails.sort(compareSeverityLevel).forEach(metric => {
      const {
        metricName: metricTitle,
        score: metricScore,
        messageHolders: messageHolders,
        severityLevel: metricSeverityLevel,
        showAsBoolean,
      } = metric;
      metricList.push(
        <SufficiencyIndicator
          key={metricTitle}
          className={classes.metric}
          classes={metricIndicatorClasses}
          severityLevel={metricSeverityLevel}
          title={metricTitle}
          score={metricScore}
          showAsBoolean={showAsBoolean}
          data-qa={"sufficiency-panel-metric"}
          messageHolders={messageHolders}
        />
      );
    });
  }

  const className = classNames(classes.container, classNameProp);

  return (
    <section className={className} data-qa="sufficiency-panel-section" {...rest}>
      <header className={classes.header}>
        <SufficiencyIndicator
          className={classes.indicator}
          severityLevel={severityLevel}
          title={title}
          score={score}
          data-qa={"sufficiency-panel-header"}
        />
      </header>
      {description && (
        <p className={classes.description} data-qa="sufficiency-panel-description">
          {description}
        </p>
      )}
      {metricList.length > 0 && (
        <div className={classes.metricSection} data-qa="sufficiency-panel-metrics">
          {metricList}
        </div>
      )}
    </section>
  );
};

export default withStiboStyles(styles)(SufficiencyPanelSection);
