import * as React from "react";
import { withStiboStyles, WithStiboStyles, CircularProgress, createStyles } from "tim-ui";
import classnames = require("classnames");
import Overlay from "./Overlay";

const styles = stiboTheme =>
  createStyles({
    inner: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  });

export type classes = "inner";

class Spinner extends React.Component<
  { withBackground?: boolean; withTransparentBackground?: boolean; size?: number } & WithStiboStyles<classes>
> {
  render() {
    const { classes, size, ...rest } = this.props;

    return (
      <Overlay {...rest}>
        <div className={classes.inner}>
          <CircularProgress size={size} />
        </div>
      </Overlay>
    );
  }
}

export default withStiboStyles(styles)(Spinner);
