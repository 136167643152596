import { orderBy } from "lodash";

import { AttributeGroupMember, IUngroupedSection } from "./types";
import { SectionStoreBase } from "./SectionStoreBase";
import { AttributeGroup } from "../Product/types";

interface UngroupedSectionStoreProps {
  attributeGroups: AttributeGroup[];
  name: string;
}

class UngroupedSectionStore extends SectionStoreBase implements IUngroupedSection {
  members;
  constructor(props: UngroupedSectionStoreProps) {
    super();
    const { name, attributeGroups } = props;
    const cleanedHierarchy = this.omitAttributeGroupInUngroupedSection(this.createHierarchy(attributeGroups, name));

    this.name = name;
    this.members = orderBy(
      cleanedHierarchy.map(attribute => {
        return new AttributeGroupMember(attribute);
      }),
      this.orderMembers,
      ["asc"]
    );
  }

  private omitAttributeGroupInUngroupedSection(attributeGroup: AttributeGroup[]): AttributeGroup[] {
    return attributeGroup
      .map(group => {
        return group.level === 0 ? this.rootLevelOmit(group) : this.higherLevelOmit(group);
      })
      .filter(group => group !== null) as AttributeGroup[];
  }

  private removeNestedWrongSections = (attributeGroups: AttributeGroup[], name: string) => {
    return attributeGroups.map(group => {
      const cleaned = group.childGroups && group.childGroups.filter(g => g.section && g.section.label === name);
      return group.withChildGroups(this.removeNestedWrongSections(cleaned, name));
    });
  };

  private createHierarchy = (attributeGroups: AttributeGroup[], name: string): AttributeGroup[] => {
    return this.removeNestedWrongSections(attributeGroups, name).filter(
      this.conditionToOmitRedundantGroupAlreadyIncludedAsAChildOfOtherGroups
    );
  };

  private conditionToOmitRedundantGroupAlreadyIncludedAsAChildOfOtherGroups = (g: AttributeGroup): boolean => {
    return g.level < 2;
  };

  private rootLevelOmit(group: AttributeGroup): AttributeGroup | null {
    return group.hasAttributes() ? group : null;
  }
  private higherLevelOmit(group: AttributeGroup): AttributeGroup | null {
    return group.isFilled() ? group : null;
  }
}

export { UngroupedSectionStore };
